import { editorViewCtx } from '@milkdown/core';
import { paragraphSchema } from '@milkdown/preset-commonmark';
import { findParentNode } from '@milkdown/prose';
import { $command } from '@milkdown/utils';
import { lift, setBlockType, wrapIn } from 'prosemirror-commands';

function runToggle(ctx, type, attrs) {
  const editorView = ctx.get(editorViewCtx);
  const parentNode = findParentNode((node) => node.type === type)(editorView.state.selection);

  if (parentNode) {
    if (type.isTextblock) {
      if (type.name === 'heading' && parentNode.node.attrs.level !== attrs?.level) {
        return setBlockType(type, attrs);
      }

      const paragraphType = paragraphSchema.type(ctx);

      return setBlockType(paragraphType);
    }

    return lift;
  }

  const fn = type.isTextblock ? setBlockType : wrapIn;

  return fn(type, attrs);
}

export const toggleNodeTypeCommand = $command(
  'ToggleNodeType',
  (ctx) =>
    ({ type, attrs = {} }) =>
      runToggle(ctx, type, attrs),
);

export const toggleSchemaCommand = $command('ToggleSchema', (ctx) => ({ schema, attrs = {} }) => {
  const type = schema.type(ctx);
  return runToggle(ctx, type, attrs);
});
