import { linkSchema } from '@milkdown/preset-commonmark';
import { findParentNode } from '@milkdown/prose';

import { DEFAULT_TOOLTIP_OPTIONS, TooltipProvider } from '../tooltip_plugin';
import SlashMenuComponent from './component';
import { slashMenuConfigCtx } from './config';
import slashMenu from './slash';

export default class SlashMenu {
  constructor(ctx) {
    this.ctx = ctx;
    this.content = new SlashMenuComponent(this.ctx.get(slashMenuConfigCtx.key));
    this.provider = new TooltipProvider({
      setEditorAsBoundary: false,
      content: this.content.domElement,
      debounce: 50,
      shouldShow: this.shouldShow.bind(this),
      tooltipOptions: {
        placement: 'bottom-start',
        classes: `${DEFAULT_TOOLTIP_OPTIONS.classes} tw-flex-1 tw-max-h-full tw-overflow-auto`,
      },
    });
    this.trigger = '/';

    this.content.on('hide', () => this.provider.hide());

    this.provider.on('connected', () => {
      this.provider.tooltipController.itemElements(this.provider.element).floating.classList.add('tw-flex');
    });

    this.provider.on('show', () => {
      requestAnimationFrame(() => {
        this.content.focus();
      });

      this.setOpen(true);
    });

    this.provider.on('hide', () => {
      requestAnimationFrame(() => {
        this.content.reset();
      });

      this.setOpen(false);
    });
  }

  shouldShow(view) {
    const currentTextBlockContent = this.getContent(view);

    if (!currentTextBlockContent) return false;

    const target = currentTextBlockContent.at(-1);

    if (!target) return false;

    return Array.isArray(this.trigger) ? this.trigger.includes(target) : this.trigger === target;
  }

  getContent(view, matchNode = (node) => node.type.name === 'paragraph') {
    const { state } = view;
    const { selection } = state;
    const { empty, $from, from, to } = selection;

    const isSlashChildren = this.content.domElement.contains(document.activeElement);
    const notHasFocus = !view.hasFocus() && !isSlashChildren;
    const isReadonly = !view.editable;

    if (notHasFocus || isReadonly || !empty || !selection) return null;

    const paragraph = findParentNode(matchNode)(view.state.selection);
    const isNotInParagraph = !paragraph;

    if (isNotInParagraph) return null;

    let isLink = false;

    state.doc.nodesBetween(from, to, (node) => {
      if (node.marks.length > 0) {
        for (const mark of node.marks) {
          if (mark.type !== linkSchema.mark.type(this.ctx)) continue;

          isLink = true;
          break;
        }
      }
    });

    if (isLink) return null;

    return $from.parent.textBetween(Math.max(0, $from.parentOffset - 500), $from.parentOffset, undefined, '\uFFFC');
  }

  handleKeyDown(event) {
    this.content.handleKeyDown(event);
  }

  setOpen(opened) {
    this.ctx.update(slashMenu.key, (prev) => ({
      ...prev,
      opened,
    }));
  }

  update(updatedView, prevState) {
    this.provider.update(updatedView, prevState);
  }

  destroy() {
    this.provider.destroy();
    this.content.remove();
  }
}
