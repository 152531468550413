import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  initialize() {
    this.props = {
      url: null,
    };
  }

  connect() {
    if (!this.url) {
      return;
    }

    if (this.disableTurbo) {
      window.location = this.url;
      return;
    }

    window.Turbo.visit(this.url, { action: this.action });
  }

  get disableTurbo() {
    return this.element.dataset.disableTurbo === 'true';
  }

  get url() {
    if (this.element.dataset.url) {
      this.props.url = this.element.dataset.url;
      this.element.removeAttribute('data-url');
    }

    return this.props.url;
  }

  get action() {
    return this.element.dataset.action;
  }
}
