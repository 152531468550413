import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['spinner'];
  }

  disconnect() {
    this.done();
  }

  loading() {
    if (!this.spinnerTarget.hidden) {
      return;
    }

    this.element.disabled = true;
    this.element.classList.add('disabled');
    this.spinnerTarget.hidden = false;
  }

  done() {
    if (this.spinnerTarget.hidden) {
      return;
    }

    this.spinnerTarget.hidden = true;
    this.element.classList.remove('disabled');
    this.element.disabled = false;
  }

  triggerLoading({ detail: { target = null } }) {
    if (!target || (!target.contains(this.element) && (!target.id || target.id !== this.form))) {
      return;
    }

    this.loading();
  }

  triggerDone({ detail: { target = null } }) {
    if (!target || (!target.contains(this.element) && (!target.id || target.id !== this.form))) {
      return;
    }

    this.done();
  }

  get form() {
    return this.element.getAttribute('form');
  }
}
