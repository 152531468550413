import I18n from '@/fuse/javascript/i18n';
import ApplicationController from 'modules/application_controller';
import toSnakeCase from 'plugins/string/to_underscore';
import isArray from 'plugins/utilities/is_array';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

export default class extends ApplicationController {
  static get targets() {
    return ['label', 'body', 'input'];
  }

  static get values() {
    return {
      initialState: {
        type: String,
        default: 'default',
      },
      initialValidationMessage: {
        type: String,
        default: '',
      },
    };
  }

  static get classes() {
    return ['validityMessage'];
  }

  initialize() {
    this.isValid = true;
    this.alreadyHadError = false;
    this.submitError = false;
    this.validityMessageTarget = null;
    this.numberRawValue = '';
    this.formNode = null;

    this.inputTarget.stimulusController = this;
  }

  connect() {
    this.formNode = this.element.closest('form');
    this.initState();

    if (!this.hasInputTarget) return;

    if (this.inputTarget.type === 'number') {
      this.numberRawValue = this.inputTarget.value;
    }

    if (this.formNode) {
      this.dispatch('added', {
        target: this.formNode,
        detail: {
          stimulusController: this,
          name: this.inputTarget.name,
        },
      });
    }

    this.initState();
  }

  disconnect() {
    if (!this.hasInputTarget) return;

    if (this.formNode) {
      this.dispatch('removed', {
        target: this.formNode,
        detail: {
          name: this.inputTarget.name,
        },
      });

      this.formNode = null;
    }
  }

  initState() {
    if (!['success', 'warning', 'error'].includes(this.initialStateValue)) return;

    this.setValidity(this.initialStateValue, this.initialValidationMessageValue);
  }

  handleBlur() {
    if (this.inputTarget.type === 'number') {
      const value = Number(this.numberRawValue.replace(',', '.')) || 0;
      let normalizedValue = value;

      if (this.inputTarget.step !== 'any') {
        const step = Number(this.inputTarget.step) || 1;
        normalizedValue = Math.round(value / step) * step;
      }

      if (Number(this.inputTarget.value) !== normalizedValue) {
        this.inputTarget.value = normalizedValue;
        this.inputTarget.dispatchEvent(new Event('input'));
        this.inputTarget.dispatchEvent(new Event('change'));
      }
    }

    if (!this.validateOnBlur || this.submitError) {
      return;
    }

    this.triggerValidate();
  }

  handleInput(event) {
    if (this.inputTarget.type === 'number') {
      if (!['.', ','].includes(event.data)) {
        this.numberRawValue = this.inputTarget.value;
      } else {
        event.stopPropagation();
        event.preventDefault();

        return;
      }
    }

    if (!this.validateOnChange) {
      return;
    }

    this.triggerValidate();
  }

  handleChange(event) {
    if (this.inputTarget.type === 'number') {
      if (this.numberRawValue !== this.inputTarget.value) {
        event.stopPropagation();
        event.preventDefault();

        return;
      }
    }

    if (!this.validateOnChange) {
      return;
    }

    this.triggerValidate();
  }

  resetValidity() {
    this.setValidity('reset');
    this.isValid = true;
    this.alreadyHadError = false;
    this.submitError = false;
  }

  triggerValidate() {
    if (!this.isValid || this.alreadyHadError) {
      this.validate();
    }
  }

  setInvalid(message) {
    this.alreadyHadError = true;
    this.setValidity('error', message || this.inputTarget.validationMessage);
  }

  setValidity(type, message) {
    if (type === 'reset') {
      this.setValidity(this.initialStateValue, this.initialValidationMessageValue);
      return;
    }

    for (const state of ['success', 'warning', 'error']) {
      if (state === type) {
        this.element.classList.add(state);
      } else {
        this.element.classList.remove(state);
      }
    }

    const noMessage = message === '' || message === null || typeof message === 'undefined';

    if (noMessage) {
      if (this.validityMessageTarget) {
        this.validityMessageTarget.remove();
        this.validityMessageTarget.innerHTML = '';
      }

      return;
    }

    if (!this.validityMessageTarget) {
      this.validityMessageTarget = document.createElement('p');
      this.validityMessageTarget.classList.add(...this.validityMessageClasses);
    }

    this.validityMessageTarget.innerHTML = message;

    if (this.bodyTarget.nextElementSibling !== this.validityMessageTarget) {
      this.bodyTarget.insertAdjacentElement('afterend', this.validityMessageTarget);
    }
  }

  validate(isSubmit = false) {
    if (isSubmit && !this.disableHtml5Validation && typeof this.inputTarget.value === 'string') {
      this.inputTarget.value = this.inputTarget.value.trim();
    }

    let valid = this.checkHtml5Validity();

    if (valid) {
      valid = this.checkCustomValidity();
    }

    if (valid && isSubmit) {
      valid = this.checkCustomValidity(this.submitConstraints);
      this.submitError = !valid;
    }

    if (valid) {
      this.setValidity(null, null);
    }

    this.isValid = valid;
    this.dispatch('validityChanged', {
      detail: {
        name: this.inputTarget.name,
        isValid: valid,
      },
    });

    return valid;
  }

  checkHtml5Validity() {
    if (this.disableHtml5Validation) {
      return true;
    }

    if (this.inputTarget.checkValidity()) {
      return true;
    }

    const validityKeys = [
      'badInput',
      'patternMismatch',
      'rangeOverflow',
      'rangeUnderflow',
      'stepMismatch',
      'tooLong',
      'tooShort',
      'typeMismatch',
      'valueMissing',
    ];

    for (const validityKey of validityKeys) {
      if (!this.inputTarget.validity[validityKey]) {
        continue;
      }

      const snakeCaseValidityKey = toSnakeCase(validityKey);
      const type = this.inputTarget.type;
      let errorMessage;

      if (validityKey === 'badInput') {
        if (type === 'number') {
          errorMessage = I18n.t(`fuse.form.errors.${snakeCaseValidityKey}.${type}`);
        } else {
          errorMessage = I18n.t(`fuse.form.errors.${snakeCaseValidityKey}.default`);
        }
      } else if (validityKey === 'patternMismatch') {
        errorMessage = I18n.t(`fuse.form.errors.${snakeCaseValidityKey}.default`);

        if (this.element.placeholder) {
          errorMessage += ` ${I18n.t(`fuse.form.errors.${snakeCaseValidityKey}.example`, {
            example: this.inputTarget.placeholder,
          })}`;
        }
      } else if (validityKey === 'typeMismatch') {
        errorMessage = I18n.t(`fuse.form.errors.${snakeCaseValidityKey}.${type}`);
      } else if (validityKey === 'valueMissing') {
        errorMessage = I18n.t(`fuse.form.errors.${snakeCaseValidityKey}`);
      } else {
        let value;

        if (validityKey === 'rangeOverflow') {
          value = this.inputTarget.getAttribute('max');
        } else if (validityKey === 'rangeUnderflow') {
          value = this.inputTarget.getAttribute('min');
        } else if (validityKey === 'tooLong') {
          value = this.inputTarget.getAttribute('maxlength');
        } else if (validityKey === 'tooShort') {
          value = this.inputTarget.getAttribute('minlength');
        } else if (validityKey === 'stepMismatch') {
          value = this.inputTarget.getAttribute('step') || 1;
        }

        errorMessage = I18n.t(`fuse.form.errors.${snakeCaseValidityKey}`, { value });
      }

      this.setInvalid(errorMessage);

      return false;
    }

    return true;
  }

  checkCustomValidity(constraints = this.constraints) {
    if (!constraints) {
      return true;
    }

    if (!isArray(constraints)) {
      constraints = [constraints];
    }

    for (const constraint of constraints) {
      const { key, pattern = null, errorMatches = false, message = null, target = null, value = null } = constraint;
      let result = !errorMatches;

      if (pattern) {
        const regexp = new RegExp(pattern, '');
        result = regexp.test(this.inputTarget.value);
      } else if (key === 'equals') {
        result = this.inputTarget.value === document.querySelector(target)?.value;
      } else if (key === 'requiredIfTargetEqualsValue') {
        result = document.querySelector(target)?.value !== value || this.inputTarget.value !== '';
      } else if (isVariableDefinedNotNull(this.inputTarget.dataset[key])) {
        if (this.inputTarget.dataset[key] === errorMatches.toString()) {
          result = errorMatches;
        }
      }

      if ((errorMatches && result) || (!errorMatches && !result)) {
        const snakeCaseKey = toSnakeCase(key);
        let errorMessage = message;

        if (!errorMessage) {
          const missingTranslationRegexp = /^\[missing ".+" translation]$/;
          errorMessage = I18n.t(`fuse.form.errors.${snakeCaseKey}`);

          if (missingTranslationRegexp.test(errorMessage)) {
            errorMessage = '';
          }
        }

        this.setInvalid(errorMessage);

        return false;
      }
    }

    return true;
  }

  get validateOnBlur() {
    if (this.element.dataset.validateOnBlur) {
      return this.element.dataset.validateOnBlur === 'true';
    }

    return true;
  }

  get validateOnChange() {
    if (this.element.dataset.validateOnChange) {
      return this.element.dataset.validateOnChange === 'true';
    }

    return true;
  }

  get disableHtml5Validation() {
    if (this.element.dataset.disableHtml5Validation) {
      return this.element.dataset.disableHtml5Validation === 'true';
    }

    return false;
  }

  get constraints() {
    if (this.element.dataset.constraints) {
      return JSON.parse(this.element.dataset.constraints);
    }

    return null;
  }

  get submitConstraints() {
    if (this.element.dataset.submitConstraints) {
      return JSON.parse(this.element.dataset.submitConstraints);
    }

    return null;
  }

  get labelText() {
    if (this.hasLabelTarget) return this.labelTarget.textContent.trim();
    if (this.hasInputTarget) {
      const ariaLabel = this.inputTarget.getAttribute('aria-label')?.trim();
      if (ariaLabel) return ariaLabel;
    }

    return 'Field';
  }
}
