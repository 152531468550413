import ApplicationController from 'modules/application_controller';
import getRandomNumber from 'plugins/number/get_random_number';

export default class extends ApplicationController {
  static get targets() {
    return ['text'];
  }

  static get values() {
    return {
      typingDelay: {
        type: Number,
        default: 130,
      },
      delayVariability: {
        type: Number,
        default: 100,
      },
      wholeTextDelay: {
        type: Number,
        default: 2000,
      },
      emptyTextDelay: {
        type: Number,
        default: 1000,
      },
    };
  }

  initialize() {
    this.originalTextContent = [];
    this.timeoutId = null;
    this.currentIndex = 0;
    this.typingDirection = 'remove';
  }

  connect() {
    this.initializeTextContent();

    if (this.isTurboPreview) return;

    this.timeoutId = setTimeout(this.typeNext.bind(this), this.delay);
  }

  disconnect() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }

    for (let i = 0; i < this.textTargets.length; i++) {
      const textTarget = this.textTargets[i];

      textTarget.textContent = this.originalTextContent[i];
      textTarget.hidden = false;
    }

    this.originalTextContent = [];
    this.currentIndex = 0;
    this.typingDirection = 'remove';
  }

  initializeTextContent() {
    this.originalTextContent = this.textTargets.map((target, index) => {
      target.hidden = index !== this.currentIndex;

      return target.textContent;
    });
  }

  typeNext() {
    this.timeoutId = setTimeout(() => {
      this.timeoutId = null;

      const currentTextTarget = this.textTargets[this.currentIndex];
      const originalText = this.originalTextContent[this.currentIndex];

      if (this.typingDirection === 'add') {
        currentTextTarget.textContent = originalText.slice(0, currentTextTarget.textContent.length + 1);

        if (currentTextTarget.textContent.length === originalText.length) {
          this.typingWordEnd();
        }
      } else if (this.typingDirection === 'remove') {
        currentTextTarget.textContent = originalText.slice(0, currentTextTarget.textContent.length - 1);

        if (currentTextTarget.textContent.length === 0) {
          this.typingWordStart();
        }
      }

      this.typeNext();
    }, this.delay);
  }

  onCurrentIndexChange(newValue, oldValue) {
    this.textTargets[oldValue].hidden = true;
    this.textTargets[newValue].textContent = '';
    this.textTargets[newValue].hidden = false;
  }

  typingWordStart() {
    const oldIndex = this.currentIndex;

    this.typingDirection = 'add';
    this.currentIndex = (this.currentIndex + 1) % this.textTargets.length;

    this.textTargets[oldIndex].hidden = true;
    this.textTargets[this.currentIndex].textContent = '';
    this.textTargets[this.currentIndex].hidden = false;
  }

  typingWordEnd() {
    this.typingDirection = 'remove';
  }

  get delay() {
    let baseDelay = this.typingDelayValue;

    if (this.textTargets[this.currentIndex].textContent.length === 0) {
      baseDelay = this.emptyTextDelayValue;
    } else if (
      this.textTargets[this.currentIndex].textContent.length === this.originalTextContent[this.currentIndex].length
    ) {
      baseDelay = this.wholeTextDelayValue;
    }

    return getRandomNumber(Math.max(0, baseDelay - this.delayVariabilityValue), baseDelay + this.delayVariabilityValue);
  }
}
