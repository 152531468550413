import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import ApplicationController from 'modules/application_controller';
import fetchUppyUploadParametersForAttachmentUpload from 'plugins/utilities/fetch_uppy_upload_parameters_for_attachment_upload';
import slidesLiveDomain from 'plugins/utilities/slideslive_domain';
import uppyFileToShrineUploadedFileData from 'plugins/utilities/uppy_file_to_shrine_uploaded_file_data';

export default class extends ApplicationController {
  static get targets() {
    return [
      'attachmentDataField',
      'removeAttachmentField',
      'publicUrlField',
      'uploadButton',
      'attachmentFilename',
      'removeAttachmentButton',
      'urlField',

      'fileInput',
      'image',
      'placeholder',
      'spinner',
      'error',
      'download',
      'publicUrl',
    ];
  }

  initialize() {
    this.uppy = null;
  }

  connect() {}

  disconnect() {
    this.destroyUppy();
  }

  ignoreDragDrop(event) {
    event.preventDefault();
  }

  startDrag(event) {
    event.dataTransfer.dropEffect = 'copy';
    this.element.classList.add('active');
  }

  stopDrag() {
    this.element.classList.remove('active');
  }

  drop(event) {
    const file = event.dataTransfer.files[0];

    if (file) {
      this.upload(file);
    }
  }

  selectFile() {
    const input = document.createElement('input');
    input.hidden = true;
    input.type = 'file';
    input.accept = this.fileAccept;
    input.onchange = () => {
      const file = input.files[0];

      if (file) {
        this.upload(file);
      }

      document.body.removeChild(input);
    };

    document.body.appendChild(input);

    input.click();
  }

  removeAttachment() {
    if (this.hasRemoveAttachmentFieldTarget) {
      this.removeAttachmentFieldTarget.value = '1';
    }

    if (this.hasAttachmentDataFieldTarget) {
      this.attachmentDataFieldTarget.value = '';
    }

    if (this.hasUrlFieldTarget) {
      this.urlFieldTarget.value = '';
    }

    if (this.hasAttachmentFilenameTarget) {
      this.attachmentFilenameTarget.textContent = '';
      this.attachmentFilenameTarget.hidden = true;
    }

    if (this.hasRemoveAttachmentButtonTarget) {
      this.removeAttachmentButtonTarget.hidden = true;
    }
  }

  upload(file) {
    this.showLoading();

    this.uppy = this.createUppy();
    this.uppy.addFile({
      name: file.name,
      type: file.type,
      data: file,
      source: 'Local',
      isRemote: false,
    });

    this.uppy.upload();
  }

  showLoading() {
    this.uploadButtonController?.loading();
  }

  hideLoading() {
    this.uploadButtonController?.done();
  }

  createUppy() {
    const uppy = new Uppy({
      autoProceed: true,
    }).use(AwsS3, {
      getUploadParameters: (file) => fetchUppyUploadParametersForAttachmentUpload(uppy, file),
    });

    uppy.on('upload-success', (file) => {
      this.finishFileUpload(file);

      this.destroyUppy();
      this.hideLoading();
    });

    return uppy;
  }

  destroyUppy() {
    if (this.uppy) {
      this.uppy.destroy();
      this.uppy = null;
    }
  }

  finishFileUpload(file) {
    const uploadedFileData = uppyFileToShrineUploadedFileData(file);

    if (this.hasRemoveAttachmentFieldTarget) {
      this.removeAttachmentFieldTarget.value = '0';
    }

    if (this.hasRemoveAttachmentButtonTarget) {
      this.removeAttachmentButtonTarget.hidden = false;
    }

    if (this.hasAttachmentDataFieldTarget) {
      this.attachmentDataFieldTarget.value = JSON.stringify(uploadedFileData);
    }

    if (this.hasUrlFieldTarget) {
      this.urlFieldTarget.value = file.meta.url ? file.meta.url : null;
    }

    if (this.hasAttachmentFilenameTarget) {
      this.attachmentFilenameTarget.textContent = file.name;
      this.attachmentFilenameTarget.hidden = false;
    }
  }

  get fileAccept() {
    return this.element.dataset.fileAccept;
  }

  get uploadButtonController() {
    if (!this.hasUploadButtonTarget) return null;

    return this.findControllerOnElement(this.uploadButtonTarget);
  }

  get uploadParametersEndpoint() {
    return `https://studio.${slidesLiveDomain()}/api/attachment_uploads/v1/presign`;
  }
}
