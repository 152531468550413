import { commandsCtx } from '@milkdown/core';
import {
    getCellsInCol,
    getCellsInRow,
    moveColCommand,
    moveRowCommand,
    selectColCommand,
    selectRowCommand,
    selectTableCommand,
} from '@milkdown/preset-gfm';
import { $prose } from '@milkdown/utils';
import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

import tableTooltip from '../tooltip';

import TableSelectorWidgetComponent from './component';

export default $prose((ctx) => {
  const key = new PluginKey('TABLE_SELECTOR');

  function onClick(target, { type, index }) {
    const { provider } = ctx.get(tableTooltip.key);

    if (!provider?.tooltipController) return;

    if (provider.isVisible) {
      provider.hide();

      return;
    }

    provider.tooltipController.itemProps(provider.element).virtualElement = {
      getBoundingClientRect: () => target.getBoundingClientRect(),
    };

    provider.show();

    const commands = ctx.get(commandsCtx);

    if (type === 'left') {
      commands.call(selectRowCommand.key, index);
    } else if (type === 'top') {
      commands.call(selectColCommand.key, index);
    } else {
      commands.call(selectTableCommand.key);
    }
  }

  function onDrop({ index: newIndex }, { type, index: oldIndex }) {
    const commands = ctx.get(commandsCtx);
    const options = {
      from: oldIndex,
      to: newIndex,
    };

    commands.call(type === 'left' ? moveRowCommand.key : moveColCommand.key, options);
  }

  return new Plugin({
    key,
    state: {
      init() {
        return {
          decorations: DecorationSet.empty,
          pos: 0,
        };
      },
      apply(tr, value, oldState, newState) {
        const leftCells = getCellsInCol(0, tr.selection);

        if (!leftCells) {
          return { decorations: DecorationSet.empty, pos: 0 };
        }

        const topCells = getCellsInRow(0, tr.selection);

        if (!topCells) {
          return { decorations: DecorationSet.empty, pos: 0 };
        }

        const createWidget = (pos, attrs) => {
          const component = new TableSelectorWidgetComponent(attrs);

          component.on('click', onClick.bind(this));
          component.on('drop', onDrop.bind(this));

          return Decoration.widget(pos, component.domElement, { key: component.id });
        };
        const [topLeft] = leftCells;

        if (!topLeft) {
          return { decorations: DecorationSet.empty, pos: 0 };
        }

        const decorations = [];

        decorations.push(createWidget(topLeft.pos + 1, { type: 'top-left' }));

        for (let i = 0; i < leftCells.length; i++) {
          const cell = leftCells[i];
          decorations.push(createWidget(cell.pos + 1, { type: 'left', index: i }));
        }

        for (let i = 0; i < topCells.length; i++) {
          const cell = topCells[i];
          decorations.push(createWidget(cell.pos + 1, { type: 'top', index: i }));
        }

        if (value.pos === topLeft.pos && oldState.doc.eq(newState.doc)) {
          return value;
        }

        return {
          decorations: DecorationSet.create(tr.doc, decorations),
          pos: topLeft.pos,
        };
      },
    },
    props: {
      decorations(state) {
        return key.getState(state).decorations;
      },
    },
  });
});
