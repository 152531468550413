import I18n from '@/fuse/javascript/i18n';
import ApplicationController from 'modules/application_controller';
import capitalizeFirstLetter from 'plugins/string/capitalize_first_letter';
import isObject from 'plugins/utilities/is_object';

export default class extends ApplicationController {
  static get targets() {
    return [
      'payButton',
      'formFieldNumber',
      'formFieldExpirationDate',
      'formFieldCvv',
      'formLabelNumber',
      'formLabelExpirationDate',
      'formLabelCvv',
      'paymentResult',
      'errors',
      'errorMessageTemplate',
    ];
  }

  connect() {
    if (this.isTurboPreview) {
      return;
    }

    if (this.element.dataset.initialized === 'true') {
      return;
    }

    this.element.dataset.initialized = true;

    if (this.hasFormLabelCvvTarget && !this.formLabelCvvTarget.dataset.originalContent) {
      this.formLabelCvvTarget.dataset.originalContent = this.formLabelCvvTarget.textContent;
    }

    this.setupForm();
  }

  disablePayButton() {
    this.payButtonTarget.disabled = true;
  }

  enablePayButton() {
    this.payButtonTarget.disabled = false;
  }

  addErrors(message, elementKeys = []) {
    if (elementKeys.length) {
      this.errorsTarget.hidden = true;
      this.errorsTarget.firstElementChild.nextElementSibling.innerHTML = '';

      this.focusFirstInvalidHostedField();

      for (const key of elementKeys) {
        const formFieldTarget = this[`formField${capitalizeFirstLetter(key)}Target`];
        const formFieldController = this.findControllerOnElement(formFieldTarget, { controller: 'fuse--form-field' });

        formFieldController?.setValidity(message === '' ? null : 'error', message);
      }

      return;
    }

    if (message === '') {
      this.errorsTarget.hidden = true;
      this.errorsTarget.firstElementChild.nextElementSibling.innerHTML = '';
    } else {
      const messageHTML = this.errorMessageTemplateTarget.innerHTML.replace('{content}', message);
      this.errorsTarget.firstElementChild.nextElementSibling.insertAdjacentHTML('beforeend', messageHTML);
      this.errorsTarget.hidden = false;
    }
  }

  removeErrors() {
    this.paymentResultTarget.innerHTML = '';

    this.errorsTarget.hidden = true;
    this.errorsTarget.firstElementChild.nextElementSibling.innerHTML = '';

    const elementKeys = ['number', 'expirationDate', 'cvv'];

    for (const key of elementKeys) {
      const formFieldTarget = this[`formField${capitalizeFirstLetter(key)}Target`];
      const formFieldController = this.findControllerOnElement(formFieldTarget, { controller: 'fuse--form-field' });

      formFieldController?.setValidity(null, null);
    }
  }

  reportErrorToSlidesLive(error) {
    fetch(this.element.dataset.braintreeErrorUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_agent: navigator.userAgent,
        error: JSON.stringify(error),
      }),
    });
  }

  setupBraintreeHostedFields() {
    return braintree.hostedFields.create({
      authorization: this.braintreeClientToken,
      styles: {
        input: {
          color: 'rgba(255, 255, 255, 0.89)',
          'font-size': '14px',
          'font-family': 'monospace',
          padding: '0 20px',
        },
        '::-webkit-input-placeholder': {
          color: 'rgba(255, 255, 255, 0.5)',
        },
        ':-moz-placeholder': {
          color: 'rgba(255, 255, 255, 0.5)',
        },
        '::-moz-placeholder': {
          color: 'rgba(255, 255, 255, 0.5)',
        },
        ':-ms-input-placeholder': {
          color: 'rgba(255, 255, 255, 0.5)',
        },
      },
      fields: {
        number: {
          selector: '#ccNumber',
          placeholder: this.formFieldNumberTarget.dataset.placeholder,
        },
        cvv: {
          selector: '#ccCvv',
          placeholder: this.formFieldCvvTarget.dataset.placeholder,
        },
        expirationDate: {
          selector: '#ccExpirationDate',
          placeholder: this.formFieldExpirationDateTarget.dataset.placeholder,
        },
      },
    });
  }

  setupBraintreeThreeDS() {
    return braintree.threeDSecure.create({
      authorization: this.braintreeClientToken,
      version: 2,
      cardinalSDKConfig: {
        maxRequestRetries: 3,
      },
    });
  }

  setupBraintreeComponents() {
    const promises = [this.setupBraintreeHostedFields()];
    if (this.verifyWithThreeDS) {
      promises.push(this.setupBraintreeThreeDS());
    }

    return Promise.all(promises);
  }

  addFieldError(key, withMessage) {
    let message = '';

    if (withMessage) {
      if (key === 'number') {
        message = I18n.t('fuse.form.errors.payment.invalid_card_number');
      } else if (key === 'cvv') {
        message = I18n.t('fuse.form.errors.payment.invalid_cvv');
      } else if (key === 'expirationDate') {
        message = I18n.t('fuse.form.errors.payment.invalid_expiration_date');
      }
    }

    this.addErrors(message, [key]);
  }

  removeFieldError(key) {
    const formFieldTarget = this[`formField${capitalizeFirstLetter(key)}Target`];
    const formFieldController = this.findControllerOnElement(formFieldTarget, { controller: 'fuse--form-field' });

    formFieldController?.setValidity(null, null);
  }

  setupForm() {
    this.disablePayButton();

    this.setupBraintreeComponents()
      .then((instances) => {
        this.hostedFields = instances[0];
        this.threeDS = instances[1];

        this.hostedFields.on('focus', this.focusChangedHostedField.bind(this));
        this.hostedFields.on('blur', this.focusChangedHostedField.bind(this));
        this.hostedFields.on('validityChange', this.validityChangedHostedField.bind(this));
        this.hostedFields.on('cardTypeChange', this.cardTypeChangedHostedField.bind(this));
        this.hostedFields.on('inputSubmitRequest', this.triggerSubmit.bind(this));

        this.enablePayButton();
      })
      .catch((error) => {
        this.addErrors(I18n.t('fuse.form.errors.payment.server.braintree_error'));
        console.warn('Braintree setup error:', error);
        this.reportErrorToSlidesLive(error);
      });
  }

  validateHostedFields(event) {
    let valid = true;

    const state = this.hostedFields.getState();
    for (const key of Object.keys(state.fields)) {
      const field = state.fields[key];

      if (!field.isValid) {
        valid = false;
        this.addFieldError(key, true);
      }
    }

    if (!valid) {
      event.preventDefault();
    }
  }

  async createNoncePayload(event) {
    const { resume, cancel } = event.detail.intercept();

    let payload;

    try {
      payload = await this.hostedFields.tokenize(this.braintreeHostedFieldsTokenizeOptions);

      if (this.verifyWithThreeDS) {
        payload = await this.threeDS.verifyCard(this.braintreeThreeDSVerifyCardOptions(payload));
      }
    } catch (err) {
      payload = undefined;

      this._showPaymentError(err);
    }

    if (payload) {
      event.detail.fetchOptions.body.append('payment_method_nonce', payload.nonce);

      resume();
    } else {
      cancel();
    }
  }

  focusFirstInvalidHostedField() {
    const fieldsState = this.hostedFields.getState().fields;

    if (fieldsState.number.isFocused || fieldsState.expirationDate.isFocused || fieldsState.cvv.isFocused) {
      return;
    }

    if (!fieldsState.number.isValid) {
      if (!fieldsState.number.isFocused) {
        this.hostedFields.focus('number');
      }
      return;
    }

    if (!fieldsState.expirationDate.isValid) {
      if (!fieldsState.expirationDate.isFocused) {
        this.hostedFields.focus('expirationDate');
      }

      return;
    }

    if (!fieldsState.cvv.isValid) {
      if (!fieldsState.cvv.isFocused) {
        this.hostedFields.focus('cvv');
      }
    }
  }

  validityChangedHostedField(event) {
    const emittedBy = event.emittedBy;
    const field = event.fields[emittedBy];

    if (!field.isValid) {
      this.addFieldError(emittedBy, true);
    } else {
      this.removeFieldError(emittedBy);

      if (!field.isPotentiallyValid) {
        if (event.emittedBy === 'number') {
          this.hostedFields.focus('expirationDate');
        } else if (event.emittedBy === 'expirationDate') {
          this.hostedFields.focus('cvv');
        }
      }
    }
  }

  triggerSubmit() {
    if (this.element.requestSubmit) {
      this.element.requestSubmit();
      return;
    }

    const submitButton = this.element.querySelector('[type="submit"]');

    if (submitButton) {
      submitButton.click();
    }
  }

  focusChangedHostedField(event) {
    const emittedBy = event.emittedBy;
    const field = event.fields[emittedBy];
    const formFieldTarget = this[`formField${capitalizeFirstLetter(emittedBy)}Target`];
    const formLabelTarget = this[`formLabel${capitalizeFirstLetter(emittedBy)}Target`];

    formFieldTarget.classList.toggle('active', field.isFocused);

    if (field.isFocused) {
      formLabelTarget.click();
    }
  }

  cardTypeChangedHostedField(event) {
    const clearCardType = () => {
      this.element.dataset.cardType = '';
      this.formLabelCvvTarget.textContent = this.formLabelCvvTarget.dataset.originalContent;
    };

    if (event.cards.length !== 1) {
      clearCardType();
      return;
    }

    const card = event.cards[0];

    if (!card.supported) {
      clearCardType();
      return;
    }

    this.element.dataset.cardType = card.type;
    this.formLabelCvvTarget.textContent = card.code.name;
  }

  clientInfoValueForInput(name) {
    const input = this.element.querySelector(`input[name="${name}"], select[name="${name}"]`);

    if (!input) {
      return '';
    }

    return input.value;
  }

  braintreeThreeDSVerifyCardOptions(payload) {
    const clientInfo = this.clientInfo;
    let normalizedCustomerName;

    if (clientInfo.customer_name.normalize) {
      normalizedCustomerName = clientInfo.customer_name.normalize('NFKD').replace(/[^a-z0-9 ]/gi, '');
    } else {
      normalizedCustomerName = clientInfo.customer_name.replace(/[^a-z0-9 ]/gi, '');
    }

    const [clientGivenName, ...clientSurnameParts] = normalizedCustomerName.split(' ');
    const clientSurname = clientSurnameParts.join(' ');

    return {
      onLookupComplete(data, next) {
        next();
      },
      amount: this.element.dataset.amount,
      nonce: payload.nonce,
      bin: payload.details.bin,
      email: clientInfo.email,
      billingAddress: {
        givenName: clientGivenName,
        surname: clientSurname,
        streetAddress: clientInfo.address.street_1,
        extendedAddress: clientInfo.address.street_2,
        locality: clientInfo.address.city,
        region: clientInfo.address.state,
        postalCode: clientInfo.address.postal_code,
        countryCodeAlpha2: clientInfo.address.country,
      },
    };
  }

  showServerErrors(event) {
    const response = event.detail[0];

    if (response.braintree_errors) {
      const braintreeErrors = response.braintree_errors;

      if (braintreeErrors.errors) {
        for (const error of braintreeErrors.errors) {
          this.addErrors(`${error.message} [${error.code}, ${error.attribute}]`);
        }
      }

      if (braintreeErrors.processor_declined) {
        const error = braintreeErrors.processor_declined;

        this.addErrors(
          `${error.processor_response_text} [${error.processor_response_code}, ${error.processor_response_type}]`,
        );
      }

      if (braintreeErrors.settlement_declined) {
        const error = braintreeErrors.settlement_declined;

        this.addErrors(`${error.processor_settlement_response_text} [${error.processor_settlement_response_code}]`);
      }

      if (braintreeErrors.gateway_rejected) {
        const error = braintreeErrors.gateway_rejected;

        this.addErrors(error.gateway_rejection_reason);
      }

      if (braintreeErrors.failed) {
        this.addErrors(I18n.t('fuse.form.errors.payment.failed'));
      }

      if (braintreeErrors.unknown_status) {
        const error = braintreeErrors.unknown_status;

        this.addErrors(I18n.t('fuse.form.errors.payment.unknown_status', { status: error.status }));
      }
    } else if (response.errors) {
      for (const error of response.errors) {
        this.addErrors(error);
      }
    } else {
      this.addErrors(I18n.t('fuse.form.errors.payment.server.braintree_error'));
    }
  }

  get braintreeHostedFieldsTokenizeOptions() {
    const removeEmptyValues = (object) => {
      for (const key of Object.keys(object)) {
        if (isObject(object[key])) {
          object[key] = removeEmptyValues(object[key]);
        }

        if (!object[key] || object[key] === '' || (isObject(object[key]) && Object.keys(object[key]).length === 0)) {
          delete object[key];
        }
      }

      return object;
    };

    const clientInfo = this.clientInfo;
    const [clientGivenName, ...clientSurnameParts] = clientInfo.customer_name.split(' ');
    const clientSurname = clientSurnameParts.join(' ');

    let options = {
      cardholderName: clientInfo.customer_name,
      billingAddress: {
        company: clientInfo.company.name,
        firstName: clientGivenName,
        lastName: clientSurname,
        streetAddress: clientInfo.address.street_1,
        extendedAddress: clientInfo.address.street_2,
        locality: clientInfo.address.city,
        region: clientInfo.address.state,
        postalCode: clientInfo.address.postal_code,
        countryCodeAlpha2: clientInfo.address.country,
      },
    };

    options = removeEmptyValues(options);

    return options;
  }

  get clientInfo() {
    if (this.element.dataset.clientInfo && this.element.dataset.clientInfo !== '') {
      return JSON.parse(this.element.dataset.clientInfo);
    }

    return {
      customer_name: this.clientInfoValueForInput('billing[customer_name]'),
      email: this.clientInfoValueForInput('billing[email]'),
      address: {
        street_1: this.clientInfoValueForInput('billing[address][street_1]'),
        street_2: this.clientInfoValueForInput('billing[address][street_2]'),
        city: this.clientInfoValueForInput('billing[address][city]'),
        state: this.clientInfoValueForInput('billing[address][state]'),
        country: this.clientInfoValueForInput('billing[address][country]'),
        postal_code: this.clientInfoValueForInput('billing[address][postal_code]'),
      },
      company: {
        name: this.clientInfoValueForInput('billing[company][name]'),
        reg_no: this.clientInfoValueForInput('billing[company][reg_no]'),
        vat_reg_no: this.clientInfoValueForInput('billing[company][vat_reg_no]'),
      },
    };
  }

  get braintreeClientToken() {
    return this.element.getAttribute('data-braintree-client-token');
  }

  get verifyWithThreeDS() {
    return this.element.getAttribute('data-braintree-require-three-ds') === 'true';
  }

  _showPaymentError(err) {
    console.warn('Payment error:', err);

    this.reportErrorToSlidesLive(err);

    if (err.code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
      this.addErrors(I18n.t('fuse.form.errors.payment.hosted_fields.fields_empty'));
    } else if (err.code === 'HOSTED_FIELDS_FIELDS_INVALID') {
      if (err.details && err.details.invalidFields) {
        for (const key of Object.keys(err.details.invalidFields)) {
          this.addFieldError(key, true);
        }
      } else {
        this.addErrors('', ['number', 'expirationDate', 'cvv']);
      }
    } else if (err.code === 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE') {
      this.addErrors('');
    } else if (err.code === 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED') {
      this.addErrors(I18n.t('fuse.form.errors.payment.hosted_fields.tokenization_cvv_verification_failed'), ['cvv']);
    } else if (err.code === 'HOSTED_FIELDS_FAILED_TOKENIZATION') {
      this.addErrors(I18n.t('fuse.form.errors.payment.hosted_fields.failed_tokenization'));
    } else if (err.code === 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR') {
      this.addErrors(I18n.t('fuse.form.errors.payment.hosted_fields.tokenization_network_error'));
    } else if (err.code === 'THREEDS_AUTHENTICATION_IN_PROGRESS') {
      this.addErrors(I18n.t('fuse.form.errors.payment.3ds.authentication_in_progress'));
    } else if (err.code === 'THREEDS_MISSING_VERIFY_CARD_OPTION') {
      this.addErrors(I18n.t('fuse.form.errors.payment.3ds.missing_verify_card_option'));
    } else if (err.code === 'THREEDS_JWT_AUTHENTICATION_FAILED') {
      this.addErrors(I18n.t('fuse.form.errors.payment.3ds.jwt_authentication_failed'));
    } else if (err.code === 'THREEDS_LOOKUP_TOKENIZED_CARD_NOT_FOUND_ERROR') {
      this.addErrors(I18n.t('fuse.form.errors.payment.3ds.lookup_tokenized_card_not_found'));
    } else if (err.code === 'THREEDS_LOOKUP_VALIDATION_ERROR') {
      if (err.details && err.details.originalError) {
        const oErr = err.details.originalError;

        if (oErr.details && oErr.details.originalError) {
          const ooErr = oErr.details.originalError;

          if (ooErr.error && ooErr.error.message) {
            this.addErrors(ooErr.error.message);
          }
        }
      }

      this.addErrors(I18n.t('fuse.form.errors.payment.3ds.lookup_validation_error'));
    } else if (err.code === 'THREEDS_LOOKUP_ERROR') {
      this.addErrors(I18n.t('fuse.form.errors.payment.3ds.lookup_error'));
    } else if (err.code === 'THREEDS_VERIFY_CARD_CANCELED_BY_MERCHANT') {
      this.addErrors(I18n.t('fuse.form.errors.payment.3ds.verify_card_canceled_by_merchant'));
    } else if (err.code === 'THREEDS_INLINE_IFRAME_DETAILS_INCORRECT') {
      this.addErrors(I18n.t('fuse.form.errors.payment.3ds.inline_iframe_details_incorrect'));
    } else {
      this.addErrors(I18n.t('fuse.form.errors.payment.unknown_error', { error: JSON.stringify(err) }));
    }
  }
}
