import ApplicationController from 'modules/application_controller';
import { AsYouType, getExampleNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';

export default class extends ApplicationController {
  static get targets() {
    return ['phone', 'number', 'countryCode'];
  }

  static get outlets() {
    return ['fuse--choices-phone-country-code'];
  }

  connect() {
    this.props = {
      defaultPlaceholder: '',
    };

    if (this.numberTarget.placeholder) {
      this.defaultPlaceholder = this.numberTarget.placeholder;
    }

    this.setPlaceholder();
    this.formatNumberAndSetValidity();
  }

  updateHiddenPhone({ target } = {}) {
    if (target === this.countryCodeTarget && this.numberTarget.value === '') {
      return;
    }

    const asYouType = new AsYouType();

    this.formatNumberAndSetValidity(asYouType);

    const number = asYouType.getNumber();

    if (!number) {
      this.phoneTarget.value = '';
      this.fireChange();
      return;
    }

    this.phoneTarget.value = number.number.replace('+', '00');
    this.fireChange();

    if (number.country && number.countryCallingCode !== this.countryCodeTarget.value) {
      this.fuseChoicesPhoneCountryCodeOutlet.setCountryCode(number.countryCallingCode);
      this.setPlaceholder();
    }
  }

  formatNumberAndSetValidity(asYouType = new AsYouType()) {
    let formattedNumber;

    if (this.numberTarget.value.startsWith('+')) {
      formattedNumber = asYouType.input(this.numberTarget.value);
    } else {
      formattedNumber = asYouType.input(`+${this.countryCodeTarget.value}${this.numberTarget.value}`);
    }

    const number = asYouType.getNumber();

    if (!number) {
      this.setValidity(null);
      return;
    }

    const formattedNational = this.formatNationalNumberAsInternational(number);

    if (number.nationalNumber !== formattedNational) {
      formattedNumber = formattedNational;
    } else {
      formattedNumber = formattedNumber.split(' ').slice(1).join(' ');
    }

    this.numberTarget.value = formattedNumber;
    this.setValidity(number);
  }

  formatNationalNumberAsInternational(number) {
    return number.formatNational().replace(/^0+/, '');
  }

  setPlaceholder() {
    if (!this.countryCodeProperties || Object.keys(this.countryCodeProperties).length === 0) {
      this.numberTarget.placeholder = this.defaultPlaceholder;
      return;
    }

    const phoneNumber = getExampleNumber(this.countryCodeProperties.country_char_code, examples);
    this.numberTarget.placeholder = this.formatNationalNumberAsInternational(phoneNumber);
  }

  setValidity(number = null) {
    if (!number) {
      this.numberTarget.dataset.invalidNumber = false;
      this.numberTarget.dataset.numberTooShort = false;
      this.numberTarget.dataset.numberTooLong = false;

      return;
    }

    const numberLength = validatePhoneNumberLength(number.number, number.country);

    if (numberLength === 'TOO_SHORT') {
      this.numberTarget.dataset.invalidNumber = false;
      this.numberTarget.dataset.numberTooLong = false;
      this.numberTarget.dataset.numberTooShort = true;

      return;
    }

    if (numberLength === 'TOO_LONG') {
      this.numberTarget.dataset.invalidNumber = false;
      this.numberTarget.dataset.numberTooShort = false;
      this.numberTarget.dataset.numberTooLong = true;

      return;
    }

    this.numberTarget.dataset.numberTooShort = false;
    this.numberTarget.dataset.numberTooLong = false;
    this.numberTarget.dataset.invalidNumber = !number.isValid();
  }

  fireChange() {
    this.phoneTarget.dispatchEvent(new Event('change', { bubbles: true }));
    this.phoneTarget.dispatchEvent(new Event('input', { bubbles: true }));
  }

  get countryCodeProperties() {
    return this.fuseChoicesPhoneCountryCodeOutlet.value?.customProperties;
  }

  get defaultPlaceholder() {
    return this.props.defaultPlaceholder;
  }

  set defaultPlaceholder(value) {
    this.props.defaultPlaceholder = value;
  }
}
