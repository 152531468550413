import { codeBlockSchema } from '@milkdown/preset-commonmark';
import { $view } from '@milkdown/utils';
import { TextSelection } from 'prosemirror-state';

import CodeBlockComponent from './component';
import { codeBlockConfigCtx } from './config';

export default $view(codeBlockSchema.node, (ctx) => (initialNode, view, getPos) => {
  const config = ctx.get(codeBlockConfigCtx.key);
  const content = new CodeBlockComponent(config, initialNode.attrs);
  let node = initialNode;

  function setAttr(attr, value) {
    const pos = getPos();

    if (!pos && pos !== 0) return;

    view.dispatch(view.state.tr.setNodeAttribute(pos, attr, value));
  }

  content.on('languageChanged', (value) => setAttr('language', value));

  requestAnimationFrame(() => {
    const pos = getPos() ?? 0;
    const end = pos + initialNode.nodeSize;
    const { from, to } = view.state.selection;

    if (view.hasFocus() && pos < from && to < end) {
      Promise.resolve().then(() => {
        const p = view.state.doc.resolve(pos);

        view.dispatch(view.state.tr.setSelection(TextSelection.near(p, 1)));
      });
    }
  });

  return {
    dom: content.domElement,
    contentDOM: content.contentElement,
    update: (updatedNode) => {
      if (updatedNode.type !== initialNode.type) return false;

      if (updatedNode.sameMarkup(node) && updatedNode.content.eq(node.content)) return false;

      node = updatedNode;
      content.attrs = node.attrs;

      return true;
    },
    ignoreMutation: (mutation) => {
      if (!content) return true;

      if (mutation.type === 'selection') return false;

      if (content.contentElement === mutation.target && mutation.type === 'attributes') return true;

      if (content.contentElement.contains(mutation.target)) return false;

      return true;
    },
    destroy: () => {
      content.remove();
    },
  };
});
