import ApplicationController from 'modules/application_controller';
import defer from 'plugins/utilities/defer';

export default class extends ApplicationController {
  connect() {
    defer(() => {
      this.dispatchOnDocument('typeChanged', { detail: { type: this.type }, prefix: 'search' });
    });
  }

  get type() {
    return this.element.dataset.type;
  }
}
