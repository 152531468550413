import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['captcha', 'warning', 'error'];
  }

  initialize() {
    this.clouadflareTurnstileWidgetId = undefined;
    this.googleRecaptchaWidgetId = undefined;
    this.hCaptchaWidgetId = undefined;

    if (this.debug) console.log('Initialize Captcha');
  }

  connect() {
    this.toggleParentFormButtons(false);

    if (this.debug) console.log('Connect Captcha');

    if (this.preferredProvider === 'google-recaptcha') {
      this.renderGoogleRecaptcha('connect');
    } else if (this.preferredProvider === 'hcaptcha') {
      this.renderHCaptcha('connect');
    } else {
      this.renderCloudflareTurnstile('connect');
    }
  }

  disconnect() {
    if (this.clouadflareTurnstileWidgetId) {
      window.turnstile.remove(this.clouadflareTurnstileWidgetId);
    }

    this.clouadflareTurnstileWidgetId = undefined;
    this.googleRecaptchaWidgetId = undefined;
    this.hCaptchaWidgetId = undefined;

    this.captchaTarget.innerHTML = '';
  }

  resetCaptcha() {
    this.resetCloudflareTurnstile();
    this.resetGoogleRecaptcha();
    this.resetHCaptcha();
  }

  resetCloudflareTurnstile() {
    this.toggleParentFormButtons(false);

    if (this.debug) {
      console.log('Reset Cloudflare Turnstile', this.clouadflareTurnstileWidgetId);
    }

    if (this.clouadflareTurnstileWidgetId) {
      window.turnstile.reset(this.clouadflareTurnstileWidgetId);
    }
  }

  renderCloudflareTurnstileFromCallback() {
    if (this.preferredProvider !== 'cloudflare-turnstile') return;

    this.renderCloudflareTurnstile('callback');
  }

  renderCloudflareTurnstile(source) {
    if (this.debug) {
      console.log('Render Cloudflare Turnstile', source, this.clouadflareTurnstileWidgetId, !!window.turnstile);
    }

    if (this.clouadflareTurnstileWidgetId) return;

    if (!window.turnstile) {
      console.warn('Cloudflare Turnstile is not loaded.');
      return;
    }

    this.captchaTarget.innerHTML = '';
    this.clouadflareTurnstileWidgetId = window.turnstile.render(this.captchaTarget, {
      sitekey: this.cloudflareTurnstileSiteKey,
      theme: this.theme,
      action: this.action,
      language: this.language,
      size: this.size,
      appearance: this.appearance,
      callback: (args) => {
        this.toggleParentFormButtons(true);

        this.dispatch('success', { detail: args });
      },
      'error-callback': (args) => {
        this.dispatch('error', { detail: args });

        console.warn('Cloudflare Turnstile error:', args);

        this.renderGoogleRecaptcha('cloudflare-recaptcha-error');
      },
      'expired-callback': (args) => {
        this.toggleParentFormButtons(false);

        this.dispatch('expired', { detail: args });
      },
      'before-interactive-callback': () => {
        console.log('Cloudflare Turnstile interactive challenge.');

        this.show();
      },
      'unsupported-callback': () => {
        console.warn('Unsupported browser for Cloudflare Turnstile bot verification.');

        this.renderGoogleRecaptcha('cloudflare-recaptcha-unsupported');
      },
    });

    if (this.debug) {
      console.log('Rendered Cloudflare Turnstile', this.clouadflareTurnstileWidgetId);
    }
  }

  resetGoogleRecaptcha() {
    this.toggleParentFormButtons(false);

    if (this.debug) {
      console.log('Reset Google reCaptcha', this.googleRecaptchaWidgetId);
    }

    if (this.googleRecaptchaWidgetId) {
      window.grecaptcha.reset(this.googleRecaptchaWidgetId);
    }
  }

  renderGoogleRecaptchaFromCallback() {
    if (this.preferredProvider !== 'google-recaptcha') return;

    this.renderGoogleRecaptcha('callback');
  }

  renderGoogleRecaptcha(source) {
    if (this.debug) {
      console.log('Render Google reCaptcha', source, this.googleRecaptchaWidgetId, !!window.grecaptcha);
    }

    if (this.googleRecaptchaWidgetId) return;

    if (!window.grecaptcha || !window.grecaptcha.render) {
      console.warn('Google reCaptcha is not loaded.');
      return;
    }

    this.captchaTarget.innerHTML = '';
    this.googleRecaptchaWidgetId = window.grecaptcha.render(this.captchaTarget, {
      sitekey: this.googleRecaptchaSiteKey,
      theme: this.theme,
      size: this.size,
      callback: (args) => {
        this.toggleParentFormButtons(true);

        this.dispatch('success', { detail: args });
      },
      'error-callback': (args) => {
        this.dispatch('error', { detail: args });

        console.warn('Turnstile error:', args);

        this.showError(`Google reCaptcha bot verification error: ${JSON.stringify(args)}`);
      },
      'expired-callback': (args) => {
        this.toggleParentFormButtons(false);

        this.dispatch('expired', { detail: args });
      },
    });

    if (this.debug) {
      console.log('Rendered Google reCaptcha', this.googleRecaptchaWidgetId);
    }
  }

  resetHCaptcha() {
    this.toggleParentFormButtons(false);

    if (this.debug) {
      console.log('Reset hCaptcha', this.hCaptchaWidgetId);
    }

    if (this.hCaptchaWidgetId) {
      window.hcaptcha.reset(this.hCaptchaWidgetId);
    }
  }

  renderHCaptchaFromCallback() {
    if (this.preferredProvider !== 'hcaptcha') return;

    this.renderHCaptcha('callback');
  }

  renderHCaptcha(source) {
    if (this.debug) {
      console.log('Render hCaptcha', source, this.hCaptchaWidgetId, !!window.hcaptcha);
    }

    if (this.hCaptchaWidgetId) return;

    if (!window.hcaptcha) {
      console.warn('hCaptcha is not loaded.');
      return;
    }

    this.captchaTarget.innerHTML = '';
    this.hCaptchaWidgetId = window.hcaptcha.render(this.captchaTarget, {
      sitekey: this.hCaptchaSiteKey,
      theme: this.theme,
      size: this.size,
      callback: (args) => {
        this.toggleParentFormButtons(true);

        this.dispatch('success', { detail: args });
      },
      'error-callback': (args) => {
        this.dispatch('error', { detail: args });

        console.warn('hCaptcha error:', args);

        this.renderGoogleRecaptcha('hcaptcha-error');
      },
      'expired-callback': (args) => {
        this.toggleParentFormButtons(false);

        this.dispatch('expired', { detail: args });
      },
    });

    if (this.debug) {
      console.log('Rendered hCaptcha', this.hCaptchaWidgetId);
    }
  }

  show() {
    this.element.hidden = false;
  }

  showWarning(message) {
    this.warningTarget.querySelector('[data-captcha-message]').textContent = message;
    this.warningTarget.hidden = false;
  }

  showError(message) {
    this.errorTarget.querySelector('[data-captcha-message]').textContent = message;
    this.errorTarget.hidden = false;
  }

  toggleParentFormButtons(enabled) {
    const parentForm = this.parentForm;
    if (!parentForm) return;

    const buttons = parentForm.querySelectorAll('button[type="submit"], input[type="submit"]');
    for (const button of buttons) {
      button.disabled = !enabled;
    }
  }

  get debug() {
    return this.element.dataset.debug === 'true';
  }

  get cloudflareTurnstileSiteKey() {
    return this.element.dataset.cloudflareTurnstileSiteKey;
  }

  get googleRecaptchaSiteKey() {
    return this.element.dataset.googleRecaptchaSiteKey;
  }

  get hCaptchaSiteKey() {
    return this.element.dataset.hcaptchaSiteKey;
  }

  get theme() {
    return this.element.dataset.theme || 'dark';
  }

  get action() {
    return this.element.dataset.turnstileAction || '';
  }

  get language() {
    return this.element.dataset.language || 'en';
  }

  get size() {
    return this.element.dataset.size || 'normal';
  }

  get appearance() {
    return this.element.dataset.appearance || 'always';
  }

  get parentFormId() {
    return this.element.dataset.formId;
  }

  get parentForm() {
    if (!this.parentFormId) return null;

    return document.getElementById(this.parentFormId);
  }

  get preferredProvider() {
    return this.element.dataset.preferredProvider || 'cloudflare-turnstile';
  }
}
