import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['name', 'id', 'lat', 'lng', 'country'];
  }

  initialize() {
    this.autocomplete = null;
  }

  connect() {
    if (this.isTurboPreview) return;

    this.initAutocomplete();
  }

  disconnect() {
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.nameTarget);
      this.autocomplete = null;
    }
  }

  initAutocomplete() {
    if (this.autocomplete) return;
    if (!window.isMapApiInitialized(this.initAutocomplete.bind(this))) return;

    this.autocomplete = new google.maps.places.Autocomplete(this.nameTarget);

    this.autocomplete.addListener('place_changed', this.updateLocation.bind(this));

    this.nameTarget.dataset.prevValue = this.nameTarget.value;

    this.fireEvent('ready');
  }

  updateLocation() {
    const place = this.autocomplete.getPlace();

    this.nameTarget.dataset.prevValue = this.nameTarget.value;

    if (this.hasLatTarget) {
      this.latTarget.value = place.geometry.location.lat();
    }

    if (this.hasLngTarget) {
      this.lngTarget.value = place.geometry.location.lng();
    }

    if (this.hasIdTarget) {
      this.idTarget.value = place.place_id;
    }

    if (this.hasCountryTarget) {
      this.countryTarget.value = '';
    }

    for (const component of place.address_components) {
      if (!component.types.includes('country')) continue;

      if (this.hasCountryTarget) {
        this.countryTarget.value = component.short_name;
      }

      break;
    }

    this.fireEvent();

    return true;
  }

  clearNotConfirmedLocation() {
    if (!('prevValue' in this.nameTarget.dataset) || this.nameTarget.dataset.prevValue === this.nameTarget.value)
      return;

    if (this.nameTarget.dataset.prevValue !== this.nameTarget.value) {
      this.nameTarget.value = this.nameTarget.dataset.prevValue;

      this.fireNameTargetDefaultEvents();

      return;
    }

    this.nameTarget.value = '';
    this.nameTarget.dataset.prevValue = '';

    if (this.hasLatTarget) {
      this.latTarget.value = '';
    }

    if (this.hasLngTarget) {
      this.lngTarget.value = '';
    }

    if (this.hasIdTarget) {
      this.idTarget.value = '';
    }

    if (this.hasCountryTarget) {
      this.countryTarget.value = '';
    }

    this.fireEvent();
  }

  fireEvent(event = 'change') {
    if (event !== 'ready') {
      this.fireNameTargetDefaultEvents();
    }

    this.dispatch(event, {
      detail: {
        name: this.nameTarget.value,
        id: this.hasIdTarget ? this.idTarget.value : undefined,
        country: this.hasCountryTarget ? this.countryTarget.value : undefined,
        lat: this.hasLatTarget ? Number(this.latTarget.value || 0) : undefined,
        lng: this.hasLngTarget ? Number(this.lngTarget.value || 0) : undefined,
      },
    });
  }

  fireNameTargetDefaultEvents() {
    this.nameTarget.dispatchEvent(new Event('input', { bubbles: true }));
    this.nameTarget.dispatchEvent(new Event('change', { bubbles: true }));
  }
}
