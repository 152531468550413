import { slashMenuConfigCtx } from './config';
import slashMenu from './slash';
import SlashMenu from './view';

export * from './config';
export { default as slashMenu } from './slash';

function configureSlashMenu(ctx) {
  let slashMenuView;

  const handleKeyDown = (_, event) => {
    if (!slashMenuView) return;

    if (!ctx.get(slashMenu.key).opened) return;

    slashMenuView.handleKeyDown(event);
  };

  ctx.set(slashMenu.key, {
    props: {
      handleDOMEvents: {
        keydown: handleKeyDown.bind(this),
      },
    },
    view: (view) => {
      slashMenuView = new SlashMenu(ctx, view);

      return slashMenuView;
    },
    opened: false,
  });
}

export const slashMenuPlugin = [slashMenuConfigCtx, slashMenu, configureSlashMenu];
