import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import ApplicationController from 'modules/application_controller';
import hide from 'plugins/element/hide';
import replaceWith from 'plugins/element/replace_with';
import show from 'plugins/element/show';
import fetchUppyUploadParametersForAttachmentUpload from 'plugins/utilities/fetch_uppy_upload_parameters_for_attachment_upload';
import setImgSrcFromFile from 'plugins/utilities/set_img_src_from_file';
import uppyFileToShrineUploadedFileData from 'plugins/utilities/uppy_file_to_shrine_uploaded_file_data';

export default class extends ApplicationController {
  static get targets() {
    return [
      'imageData',
      'removeImageField',
      'fileInput',
      'image',
      'placeholder',
      'spinner',
      'error',
      'download',
      'urlField',
    ];
  }

  initialize() {
    this.uppy = null;
    this.fileInputHTML = this.fileInputTarget.outerHTML;
  }

  connect() {
    this.resetFileInput();
  }

  disconnect() {
    this.destroyUppy();
  }

  ignoreDragDrop(event) {
    event.preventDefault();
  }

  startDrag(event) {
    event.dataTransfer.dropEffect = 'copy';
    this.element.classList.add('active');
  }

  stopDrag() {
    this.element.classList.remove('active');
  }

  drop(event) {
    const file = event.dataTransfer.files[0];

    if (file) {
      this.upload(file);
    }
  }

  resetFileInput() {
    replaceWith(this.fileInputTarget, this.fileInputHTML);
  }

  uploadFileFromInput(event) {
    const target = event.target;
    const file = target.files[0];

    this.upload(file);
    this.resetFileInput();
  }

  upload(file) {
    this.showLoading();

    this.uppy = this.createUppy();
    this.uppy.addFile({
      name: file.name,
      type: file.type,
      data: file,
      source: 'Local',
      isRemote: false,
    });

    this.uppy.upload();
  }

  showLoading() {
    hide(this.errorTarget, { useHiddenAttr: true });
    show(this.spinnerTarget, { useHiddenAttr: true });
  }

  hideLoadingAndError() {
    hide(this.errorTarget, { useHiddenAttr: true });
    hide(this.spinnerTarget, { useHiddenAttr: true });
  }

  showError() {
    show(this.errorTarget, { useHiddenAttr: true });
    hide(this.spinnerTarget, { useHiddenAttr: true });
  }

  remove() {
    if (this.hasRemoveImageFieldTarget) {
      this.removeImageFieldTarget.value = '1';
    }

    if (this.hasImageDataTarget) {
      this.imageDataTarget.value = '';
    }

    this.imageTarget.src = '';
    this.imageTarget.hidden = true;

    if (this.hasUrlFieldTarget) {
      this.urlFieldTarget.value = '';
    }

    if (this.hasDownloadTarget) {
      this.downloadTarget.hidden = true;
      this.downloadTarget.setAttribute('href', '');
    }

    if (this.hasPlaceholderTarget) {
      this.placeholderTarget.hidden = false;
    }
  }

  createUppy() {
    const uppy = new Uppy({
      autoProceed: true,
    }).use(AwsS3, {
      allowedMetaFields: false,
      getUploadParameters: (file) => fetchUppyUploadParametersForAttachmentUpload(uppy, file),
    });

    uppy.on('thumbnail:generated', (file, preview) => {
      this.imageTarget.src = preview;
    });

    uppy.on('upload-success', (file) => {
      this.finishFileUpload(file);
      this.hideLoadingAndError();
      this.destroyUppy();
    });

    return uppy;
  }

  destroyUppy() {
    if (this.uppy) {
      this.uppy.destroy();
      this.uppy = null;
    }
  }

  finishFileUpload(file) {
    const uploadedFileData = uppyFileToShrineUploadedFileData(file);

    if (this.hasRemoveImageFieldTarget) {
      this.removeImageFieldTarget.value = '0';
    }

    if (this.hasImageDataTarget) {
      this.imageDataTarget.value = JSON.stringify(uploadedFileData);
    }

    if (file.meta.url) {
      if (this.hasUrlFieldTarget) {
        this.urlFieldTarget.value = file.meta.url;
      }

      if (this.hasDownloadTarget) {
        this.downloadTarget.setAttribute('href', file.meta.url);
        this.downloadTarget.hidden = false;
      }
    } else {
      if (this.hasUrlFieldTarget) {
        this.urlFieldTarget.value = '';
      }

      if (this.hasDownloadTarget) {
        this.downloadTarget.setAttribute('href', '');
        this.downloadTarget.hidden = true;
      }
    }

    if (this.hasPlaceholderTarget) {
      this.placeholderTarget.hidden = true;
    }

    setImgSrcFromFile(this.imageTarget, file.data);
  }
}
