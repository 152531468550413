function uppyFileToShrineUploadedFileData(uppyFile) {
  return {
    id: uppyFile.meta.key,
    storage: uppyFile.meta.shrine_storage_key || 'cache',
    metadata: {
      size: uppyFile.size,
      filename: uppyFile.name,
      mime_type: uppyFile.type,
    },
  };
}

export default uppyFileToShrineUploadedFileData;
