const menuButtonHighlight = (buttonsConfig, activeStyleClass, { state }) => {
  const { selection, schema } = state;
  const { from, to, empty } = selection;

  for (const buttonConfig of buttonsConfig) {
    const markType = schema.marks[buttonConfig.key];
    const isActive = empty
      ? Boolean(markType.isInSet(state.storedMarks || state.doc.resolve(from).marks()))
      : state.doc.rangeHasMark(from, to, markType);

    for (const button of buttonConfig.targets) {
      if (isActive !== button.classList.contains(activeStyleClass)) {
        button.classList.toggle(activeStyleClass, isActive);
      }
    }
  }
};

export default menuButtonHighlight;
