export function findMarkPosition(mark, doc, from, to) {
  let markPos = { start: -1, end: -1 };
  doc.nodesBetween(from, to, (node, pos) => {
    // stop recursive finding if result is found
    if (markPos.start > -1) return false;

    if (markPos.start === -1 && mark.isInSet(node.marks)) {
      markPos = {
        start: pos,
        end: pos + Math.max(node.textContent.length, 1),
      };
    }

    return undefined;
  });

  return markPos;
}

export function findMarkFromAnchor(schema, ctx, view) {
  const { $anchor } = view.state.selection;

  if (!$anchor) return null;

  const { pos } = $anchor;
  const node = view.state.doc.nodeAt(pos);

  if (!node) return null;

  const mark = node.marks.find((m) => m.type === schema.mark.type(ctx));

  if (!mark) return null;

  return { pos, node, mark };
}
