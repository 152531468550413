import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['progress', 'progressText'];
  }

  set percentage(pct) {
    this.progressTarget.style.width = `${pct}%`;
    this.progressTextTarget.textContent = `${Math.round(pct)}%`;
  }
}
