import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get values() {
    return {
      offset: {
        type: Number,
        default: 0,
      },
    };
  }

  initialize() {
    this.headerHeight = 0;
    this.lastScrollPosition = 0;
    this.nextScrollPosition = 0;
    this.currentTop = 0;
    this.ticking = false;
  }

  connect() {
    this.updateHeaderHeight();

    this.lastScrollPosition = window.scrollY;
    this.currentTop = this.headerHeight + this.offsetValue;
  }

  updateHeaderHeight() {
    this.headerHeight = Number(
      window.getComputedStyle(document.querySelector('html')).getPropertyValue('--header-height')?.replace(/\D/g, '') ||
        0,
    );
  }

  updateTopFromEvent() {
    this.nextScrollPosition = window.scrollY;

    if (this.ticking) return;

    window.requestAnimationFrame(() => {
      this.updateTop(this.nextScrollPosition);
      this.ticking = false;
    });

    this.ticking = true;
  }

  updateTop(scrollY = window.scrollY) {
    const elementHeight = this.element.offsetHeight;

    if (elementHeight <= window.innerHeight - this.headerHeight) {
      this.currentTop = this.headerHeight + this.offsetValue;
    } else {
      const scrollDelta = scrollY - this.lastScrollPosition;
      const maxTopValue = this.headerHeight + this.offsetValue;
      const minTopValue = window.innerHeight - elementHeight - this.offsetValue;
      const topValue = Math.max(Math.min(this.currentTop - scrollDelta, maxTopValue), minTopValue);

      this.currentTop = topValue;
    }

    this.lastScrollPosition = scrollY;
    this.element.style.top = `${this.currentTop}px`;
  }
}
