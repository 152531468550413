import { $ctx } from '@milkdown/utils';
import generateRandomId from 'plugins/utilities/generate_random_id';

const defaultLinkTooltipMark = { mark: null, from: -1, to: -1 };
export const linkTooltipMarkCtx = $ctx({ ...defaultLinkTooltipMark }, 'linkTooltipMarkCtx');

const defaultLinkTooltipMode = { mode: 'preview' };
export const linkTooltipModeCtx = $ctx({ ...defaultLinkTooltipMode }, 'linkTooltipModeCtx');

const defaultLinkTooltipAPI = {
  addLink: () => {},
  editLink: () => {},
  removeLink: () => {},
};

export const linkTooltipAPICtx = $ctx({ ...defaultLinkTooltipAPI }, 'linkTooltipAPICtx');

const defaultLinkTooltipConfig = {
  id: `richTextLink_${generateRandomId(12)}`,
  linkHtml: '<a href=""></a>',
  fieldHtml: '<input type="url" name="{HREF_INPUT}" placeholder="Paste link…" required />',
  confirmButtonHtml: '<button type="submit">Confirm ⏎</button>',
  cancelButtonHtml: '<button type="button">Cancel</button>',
  editButtonHtml: '<button type="button">Edit</button>',
  removeButtonHtml: '<button type="button">Remove</button>',
  copyButtonHtml: null,
};

export const linkTooltipConfigCtx = $ctx({ ...defaultLinkTooltipConfig }, 'linkTooltipConfigCtx');
