import { indentConfig } from '@milkdown/plugin-indent';
import { codeBlockSchema } from '@milkdown/preset-commonmark';
import { findParentNode } from '@milkdown/prose';

const tabConfigSetting = (context, editorView = null) => {
  function setIndent(name) {
    const config = name === 'code' ? { name, type: 'space', size: 2 } : { name, type: 'tab', size: 1 };

    context.set(indentConfig.key, config);
  }

  const indentSetConfig = context.get(indentConfig.key);

  if (!editorView) {
    setIndent('text');
    return;
  }

  const type = codeBlockSchema.type(context);
  const parentNode = findParentNode((node) => node.type === type)(editorView.state.selection);

  if (parentNode && indentSetConfig.name !== 'code') {
    setIndent('code');
  } else if (!parentNode && indentSetConfig.name !== 'text') {
    setIndent('text');
  }
};

export default tabConfigSetting;
