import createElementFromHTML from 'plugins/element/create_element_from_html';
import addListener from 'plugins/utilities/add_listener';
import removeListener from 'plugins/utilities/remove_listener';

export default class LinkPreviewComponent {
  constructor(config) {
    this.data = { config };
    this.elements = {
      dom: this.renderDom(),
      input: null,
      copyButton: null,
      editButton: null,
      removeButton: null,
    };
    this.callbacks = {};
    this.listeners = [];

    this.renderChildren();
    this.initializeListeners();
  }

  initializeListeners() {
    if (this.listeners.length > 0) return;

    this.listeners.push(
      {
        target: this.editButtonElement,
        id: addListener(this.editButtonElement, 'click', this.onEdit.bind(this)),
      },
      {
        target: this.removeButtonElement,
        id: addListener(this.removeButtonElement, 'click', this.onRemove.bind(this)),
      },
    );
  }

  removeListeners() {
    for (const { target, id } of this.listeners) {
      removeListener(target, { id });
    }
  }

  renderDom() {
    return createElementFromHTML('<div class="tw-flex tw-gap-1"></div>');
  }

  renderChildren() {
    this.elements = {
      ...this.elements,
      link: this.renderLink(),
      input: null,
      copyButton: this.renderCopyButton(),
      editButton: this.renderEditButton(),
      removeButton: this.renderRemoveButton(),
    };

    this.domElement.innerHTML = '';

    this.domElement.appendChild(this.linkElement);

    if (this.copyButtonElement) {
      this.domElement.appendChild(this.copyButtonElement);
    }

    this.domElement.appendChild(this.editButtonElement);
    this.domElement.appendChild(this.removeButtonElement);
  }

  renderLink({ linkHtml } = this.config) {
    return createElementFromHTML(linkHtml);
  }

  renderCopyButton({ copyButtonHtml } = this.config) {
    if (!copyButtonHtml) return createElementFromHTML('<span hidden></span>');

    return createElementFromHTML(copyButtonHtml.replace(/\{CONTENT_ID}/g, this.getId('content')));
  }

  renderEditButton({ editButtonHtml } = this.config) {
    return createElementFromHTML(editButtonHtml);
  }

  renderRemoveButton({ removeButtonHtml } = this.config) {
    return createElementFromHTML(removeButtonHtml);
  }

  onEdit() {
    this.runCallback('edit');
  }

  onRemove() {
    this.runCallback('remove');
  }

  runCallback(name, ...attrs) {
    for (const callback of this.callbacks[name] || []) {
      callback(...attrs);
    }
  }

  on(name, callback) {
    if (!this.callbacks[name]) {
      this.callbacks[name] = [];
    }

    this.callbacks[name].push(callback);
  }

  remove() {
    for (const { target, id } of this.listeners) {
      removeListener(target, { id });
    }

    this.domElement.remove();
  }

  getId(suffix) {
    return `${this.config.id}_${suffix}`;
  }

  set config(value) {
    const rerenderChildren = JSON.stringify(this.config) !== JSON.stringify(value);

    this.data.config = value;

    if (rerenderChildren) {
      this.removeListeners();
      this.renderChildren();
      this.initializeListeners();
    }
  }

  get config() {
    return this.data.config;
  }

  set href(value) {
    this.linkElement.querySelector('a').href = value;
    this.linkElement.querySelector('a').textContent = value;
  }

  get href() {
    return this.linkElement.querySelector('a').href;
  }

  get domElement() {
    return this.elements.dom;
  }

  get linkElement() {
    return this.elements.link;
  }

  get copyButtonElement() {
    return this.elements.copyButton;
  }

  get editButtonElement() {
    return this.elements.editButton;
  }

  get removeButtonElement() {
    return this.elements.removeButton;
  }
}
