const menuButtonsAvailabilityBySelection = (buttons, { state }) => {
  const { from, to, empty } = state.selection;
  const disabled = empty || from === to;

  for (const button of buttons) {
    if (disabled === button.disabled) continue;

    button.disabled = disabled;
  }
};

export default menuButtonsAvailabilityBySelection;
