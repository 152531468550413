import { $ctx } from '@milkdown/utils';

export const defaultTableTooltipConfig = {
  tooltip: null,
  addRowBeforeHtml: '<button type="button">↑</button>',
  addRowAfterHtml: '<button type="button">↓</button>',
  addColBeforeHtml: '<button type="button">←</button>',
  addColAfterHtml: '<button type="button">→</button>',
  deleteSelectedCellsHtml: '<button type="button">∅</button>',
  setAlignLeftHtml: '<button type="button">L</button>',
  setAlignCenterHtml: '<button type="button">C</button>',
  setAlignRightHtml: '<button type="button">R</button>',
};

export const tableTooltipConfigCtx = $ctx({ ...defaultTableTooltipConfig }, 'tableTooltipConfigCtx');
