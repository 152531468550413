import { $ctx } from '@milkdown/utils';

export const defaultCodeBlockConfig = {
  languages: ['text'],
  defaultLanguage: 'text',
  id: `richTextCodeBlock_${Math.round(Math.random() * 100000)}`,
  selectLanguageHtml: '<select name="{LANGUAGE_SELECT}"></select>',
  copyButtonHtml: null,
};

export const codeBlockConfigCtx = $ctx(defaultCodeBlockConfig, 'codeBlockConfigCtx');
