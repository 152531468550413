import ApplicationController from 'modules/application_controller';
import animationsComplete from 'plugins/element/animations_complete';
import focusFirstFocusableTarget from 'plugins/element/focus_first_focusable_target';
import hide from 'plugins/element/hide';
import show from 'plugins/element/show';
import checkDelayedResult from 'plugins/utilities/check_delayed_result';
import isObject from 'plugins/utilities/is_object';

export default class extends ApplicationController {
  static get targets() {
    return ['content', 'spinner'];
  }

  initialize() {
    this.props = {
      hadInitialContent: false,
      loading: false,
      observer: null,
      abortController: null,
      trigger: null,
      instanceOptions: {},
      opened: false,
      reopen: false,
    };

    this.initObserver();
  }

  connect() {
    this.startObserve();
    this.initInitialState();
  }

  disconnect() {
    this.destroy();
  }

  destroy() {
    this.endObserve();
    this.opened = false;
    this.reopen = false;
  }

  initObserver() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach(async (mutation) => {
        if (mutation.type === 'childList') {
          if (mutation.addedNodes.length === 0) {
            return;
          }

          this.contentLoaded();
        }
      });
    });
  }

  startObserve() {
    if (!this.observer) {
      return;
    }

    this.observer.observe(this.contentTarget, {
      childList: true,
    });
  }

  initInitialState() {
    this.hadInitialContent = this.contentTarget.innerHTML.replace(/\s/g, '') !== '';

    if (this.hadInitialContent) {
      this.showContent(true);
    } else {
      this.showSpinner(true);
    }
  }

  endObserve() {
    if (!this.observer) {
      return;
    }

    this.observer.disconnect();
  }

  open() {
    if (this.opened) {
      this.reopen = true;
      return;
    }

    this.dispatch('open', { prefix: 'dialog', bubbles: false });
  }

  close() {
    this.dispatch('close', { prefix: 'dialog', bubbles: false });
  }

  propagateDialogEvent(event) {
    event.stopPropagation();

    const name = event.type.split(':').pop();

    this.runCallback(name);
  }

  resetInstance() {
    this.trigger = null;
    this.instanceOptions = {};
  }

  resetContent() {
    if (this.hadInitialContent) {
      return;
    }

    this.showSpinner(true);
    this.clearContent();
  }

  initContent() {
    if (this.hadInitialContent) {
      this.contentLoaded(true);

      return;
    }

    this.fetchContent();
  }

  async fetchContent() {
    if (!this.instanceOptions.url) {
      return;
    }

    this.runCallback('fetching');

    this.abortController = new AbortController();

    try {
      let url = this.instanceOptions.url;

      if (this.urlParams) {
        url += `${url.includes('?') ? '&' : '?'}${this.urlParams}`;
      }

      if (this.instanceOptions.urlParams) {
        url += `${url.includes('?') ? '&' : '?'}${this.instanceOptions.urlParams}`;
      }

      const method = this.instanceOptions.requestMethod || 'GET';
      const headers = {
        Accept:
          'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*; q=0.01',
        'X-Requested-With': 'XMLHttpRequest',
      };

      if (this.instanceOptions.requestContentType) {
        headers['Content-Type'] = this.instanceOptions.requestContentType;
      }

      if (this.instanceOptions.requestCsrfToken) {
        headers['X-CSRF-Token'] = this.instanceOptions.requestCsrfToken;
      }

      const body = this.instanceOptions.requestBody || undefined;

      const response = await fetch(url, {
        signal: this.abortController.signal,
        method,
        headers,
        credentials: 'include',
        body,
      });

      const contentType = response.headers.get('content-type');

      if (contentType === 'application/json') {
        const json = await response.json();
        this.fetchFromDelayedResult(this.instanceOptions.url, json);
      } else {
        const content = await response.text();
        this.setContent(content);
      }

      this.runCallback('fetched');
    } catch (err) {
      this.abortController = null;

      if (err.name === 'AbortError') {
        this.runCallback('fetchAborted');
        return;
      }

      this.close();

      console.warn(`[${this.identifier}] fetchContent error:`, err);

      this.runCallback('fetchError', { data: { error: err } });
    }
  }

  async fetchFromDelayedResult(url, response) {
    const delayedResultId = response.delayed_result_id;

    if (!delayedResultId) {
      console.warn('Loading modal from', url, ' from delayed result failed: No delayed result ID.');

      this.runCallback('fetchError');
      this.close();

      return;
    }

    const delayedResultUrl = `${this.delayedResultUrl}?id=${delayedResultId}`;

    checkDelayedResult(
      delayedResultUrl,
      (result) => {
        if (result.success) {
          this.setContent(result.html);
          this.contentLoaded();
        } else if (result.errors) {
          console.warn('Loading modal from', url, ' from delayed result failed:', result.errors);

          this.runCallback('fetchError');
          this.close();
        }
      },
      500,
    );
  }

  async contentLoaded(skipAnimation = false) {
    await animationsComplete(this.element);
    await this.showContent(skipAnimation);
    focusFirstFocusableTarget(this.contentTarget, { preventScroll: true });
  }

  triggerReloadFromEvent({ detail: { name, options = null, skipAnimation = false } }) {
    if (name !== this.name) return;

    if (options) {
      this.instanceOptions = {
        url: this.instanceOptions.url,
        requestMethod: this.instanceOptions.requestMethod,
        requestContentType: this.instanceOptions.requestContentType,
        requestCsrfToken: this.instanceOptions.requestCsrfToken,
        requestBody: this.instanceOptions.requestBody,
        ...options,
      };
    }

    if (!skipAnimation) this.showSpinner();
    this.fetchContent();
  }

  triggerOpenFromEvent({ detail: { name, trigger = null, options = {} } }) {
    if (name !== this.name) {
      return;
    }

    if (this.opened) {
      this.reopen = { trigger, options };

      return;
    }

    this.trigger = trigger;
    this.instanceOptions = options;

    if (
      this.instanceOptions.withHistory &&
      this.instanceOptions.url &&
      (!this.instanceOptions.requestMethod || this.instanceOptions.requestMethod === 'GET')
    ) {
      window.history.pushState(
        {
          modal: this.name,
          instanceOptions: this.instanceOptions,
          previousState: window.history.state,
          previousUrl: window.location.href,
        },
        undefined,
        this.instanceOptions.url,
      );
    }

    this.open();
  }

  triggerFromPopstate(event) {
    if (event.state.modal === this.name) {
      this.instanceOptions = event.state.instanceOptions;
      this.open();
    } else {
      this.close();
    }
  }

  resetHistoryState() {
    if (window.history.state.modal === this.name) {
      window.history.pushState(window.history.state.previousState, null, window.history.state.previousUrl);
    }
  }

  triggerCloseFromEvent({ detail: { name } }) {
    if (name !== this.name) return;

    this.close();
  }

  abortLoading() {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }
  }

  accept(closeModal = true, { data = undefined } = {}) {
    if (closeModal) {
      this.close();
    }

    this.runCallback('accept', { data });
  }

  reject(closeModal = true, { data = undefined } = {}) {
    if (closeModal) {
      this.close();
    }

    this.runCallback('reject', { data });
  }

  setOpened() {
    this.opened = true;
  }

  unsetOpened() {
    this.opened = false;

    if (this.reopen) {
      if (isObject(this.reopen)) {
        const { trigger, options } = this.reopen;

        this.trigger = trigger;
        this.instanceOptions = options;
      }

      this.reopen = false;

      this.open();
    }
  }

  runCallback(name, { fireModalEvent = true, data = {} } = {}) {
    if (typeof this.instanceOptions[name] === 'function') {
      this.instanceOptions[name].call(this, this);
    }

    if (fireModalEvent) {
      this.dispatch(name, { detail: { name: this.name, data } });
    }

    if (this.trigger) {
      this.dispatch(name, { target: this.trigger, bubbles: false, detail: data });
    }
  }

  setContent(content) {
    this.contentTarget.innerHTML = content;
  }

  clearContent() {
    this.setContent('');
  }

  changeContent({ target: { action, target: targetId } }) {
    if (action !== 'remove') return;

    if (!targetId || targetId !== this.contentTarget?.id || targetId !== this.contentTarget?.firstElementChild?.id)
      return;

    this.close();
  }

  async showTarget(target) {
    target.classList.add(this.animateContentInClass);

    show(target, { useHiddenAttr: true });
    await animationsComplete(target);

    target.classList.remove(this.animateContentInClass);
  }

  async hideTarget(target) {
    target.classList.add(this.animateContentOutClass);

    await animationsComplete(target);
    hide(target, { useHiddenAttr: true });

    target.classList.remove(this.animateContentOutClass);
  }

  async showContent(skipAnimation = false) {
    await animationsComplete(this.contentTarget);

    this.hideSpinner(skipAnimation);

    if (!this.contentTarget.hidden) return;

    if (skipAnimation) {
      show(this.contentTarget, { useHiddenAttr: true });

      return;
    }

    await this.showTarget(this.contentTarget);
  }

  async hideContent(skipAnimation = false) {
    await animationsComplete(this.contentTarget);

    if (this.contentTarget.hidden) return;

    if (skipAnimation) {
      hide(this.contentTarget, { useHiddenAttr: true });

      return;
    }

    await this.hideTarget(this.contentTarget);
  }

  async showSpinner(skipAnimation = false) {
    await animationsComplete(this.spinnerTarget);

    this.hideContent(skipAnimation);

    if (!this.spinnerTarget.hidden) return;

    if (skipAnimation) {
      show(this.spinnerTarget, { useHiddenAttr: true });

      return;
    }

    this.showTarget(this.spinnerTarget);
  }

  async hideSpinner(skipAnimation = false) {
    await animationsComplete(this.spinnerTarget);

    if (this.spinnerTarget.hidden) return;

    if (skipAnimation) {
      hide(this.spinnerTarget, { useHiddenAttr: true });

      return;
    }

    this.hideTarget(this.spinnerTarget);
  }

  get observer() {
    return this.props.observer;
  }

  set observer(value) {
    this.props.observer = value;
  }

  get abortController() {
    return this.props.abortController;
  }

  set abortController(value) {
    this.props.abortController = value;
  }

  get trigger() {
    return this.props.trigger;
  }

  set trigger(value) {
    this.props.trigger = value;
  }

  get instanceOptions() {
    return this.props.instanceOptions;
  }

  set instanceOptions(value) {
    this.props.instanceOptions = value;
  }

  get opened() {
    return this.props.opened;
  }

  set opened(value) {
    this.props.opened = value;
  }

  get hadInitialContent() {
    return this.props.hadInitialContent;
  }

  set hadInitialContent(value) {
    this.props.hadInitialContent = value;
  }

  get name() {
    return this.element.dataset.modalName;
  }

  get urlParams() {
    return this.element.dataset.modalUrlParams;
  }

  get delayedResultUrl() {
    return this.element.dataset.delayedResultUrl || 'https://slideslive.com/-/delayed_result';
  }

  get animateContentInClass() {
    return 'tw-animate-zoom-in';
  }

  get animateContentOutClass() {
    return 'tw-animate-zoom-out';
  }

  set persistent(value) {
    this.element.dataset.persistent = value;
  }

  set closeOnBgClick(value) {
    this.element.dataset.closeOnBgClick = value;
  }
}
