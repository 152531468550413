import { $ctx, $prose } from '@milkdown/utils';
import { Plugin, PluginKey } from 'prosemirror-state';

export default function tooltipFactory(id) {
  const tooltipSpec = $ctx({}, `${id}_TOOLTIP_SPEC`);
  const tooltipPlugin = $prose((ctx) => {
    const spec = ctx.get(tooltipSpec.key);
    return new Plugin({
      key: new PluginKey(`${id}_TOOLTIP`),
      ...spec,
    });
  });
  const result = [tooltipSpec, tooltipPlugin];

  result.key = tooltipSpec.key;
  result.pluginKey = tooltipPlugin.key;

  return result;
}
