import * as Turbo from '@hotwired/turbo-rails';

import './turbo.css';

function initializeTurbo() {
  if (!window.Turbo) {
    window.Turbo = Turbo;
  }

  document.addEventListener('turbo:before-fetch-request', (event) => {
    event.detail.fetchOptions.credentials = 'include';
  });
}

export default initializeTurbo;
