import ApplicationController from 'modules/application_controller';
import remove from 'plugins/element/remove';

export default class extends ApplicationController {
  connect() {
    this.dispatchOnWindow('open', { prefix: 'modal', detail: { name: this.modalName } });

    remove(this.element);
  }

  get modalName() {
    return this.element.dataset.modalName;
  }
}
