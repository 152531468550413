import ApplicationController from 'modules/application_controller';
import dialogPolyfill from 'dialog-polyfill';

import animationsComplete from 'plugins/element/animations_complete';
import removeBodyOverflowHidden from 'plugins/utilities/remove_body_overflow_hidden';
import setBodyOverflowHidden from 'plugins/utilities/set_body_overflow_hidden';

export default class extends ApplicationController {
  initialize() {
    this.props = {
      isOpen: false,
      acceptClose: false,
      observer: null,
    };

    this.initObserver();
  }

  connect() {
    this.isOpen = this.element.hasAttribute('open');
    this.acceptClose = false;

    if (typeof HTMLDialogElement !== 'function') {
      this.element.classList.add('tw-hidden', 'open:tw-block');
      dialogPolyfill.registerDialog(this.element);
    }

    this.startObserve();

    if (this.initOpen) {
      this.open();
    }
  }

  disconnect() {
    this.destroy();
  }

  destroy() {
    this.endObserve();
    this.close();
  }

  initObserver() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach(async (mutation) => {
        if (mutation.type === 'attributes') {
          if (mutation.attributeName === 'open') {
            const { target } = mutation;

            this.isOpen = target.hasAttribute('open');

            if (!this.isOpen) {
              removeBodyOverflowHidden();

              return;
            }

            setBodyOverflowHidden();

            target.removeAttribute('inert');

            this.dispatch('opening');

            await animationsComplete(this.element);

            this.dispatch('opened');
          }
        }
      });
    });
  }

  startObserve() {
    if (!this.observer) {
      this.initObserver();
    }

    this.observer.observe(this.element, {
      attributes: true,
    });
  }

  endObserve() {
    if (!this.observer) {
      return;
    }

    this.observer.disconnect();
    this.observer = null;
  }

  open() {
    if (this.isOpen) {
      return;
    }

    this.element.showModal();
  }

  async close() {
    if (!this.isOpen) {
      return;
    }

    if (!this.observer) {
      this.isOpen = false;
      removeBodyOverflowHidden();
    }

    this.element.classList.add('hiding');

    await animationsComplete(this.element);

    this.element.close('dismiss');

    this.element.classList.remove('hiding');
  }

  triggerClose() {
    if (this.persistent || !this.closeOnBgClick) {
      return;
    }

    if (!this.acceptClose) {
      return;
    }

    this.close();

    this.acceptClose = false;
  }

  updateAcceptClose({ target }) {
    this.acceptClose =
      target.nodeName === 'DIALOG' || (this.childrenAreNotContent && [...this.element.children].includes(target));
  }

  onCancel(event) {
    event.stopPropagation();

    if (this.persistent) {
      event.preventDefault();

      return;
    }

    this.dispatch('cancel');
  }

  async onClose(event) {
    event.stopPropagation();

    this.element.setAttribute('inert', '');

    this.dispatch('closing');

    await animationsComplete(this.element);

    this.dispatch('closed');
  }

  get isOpen() {
    return this.props.isOpen;
  }

  set isOpen(value) {
    this.props.isOpen = value;
  }

  get acceptClose() {
    return this.props.acceptClose;
  }

  set acceptClose(value) {
    this.props.acceptClose = value;
  }

  get observer() {
    return this.props.observer;
  }

  set observer(value) {
    this.props.observer = value;
  }

  get initOpen() {
    return this.element.dataset.initOpen === 'true';
  }

  get persistent() {
    return this.element.dataset.persistent === 'true';
  }

  set persistent(value) {
    this.element.dataset.persistent = value;
  }

  get closeOnBgClick() {
    return this.element.dataset.closeOnBgClick === 'true';
  }

  set closeOnBgClick(value) {
    this.element.dataset.closeOnBgClick = value;
  }

  get childrenAreNotContent() {
    return this.element.dataset.childrenAreNotContent === 'true';
  }
}
