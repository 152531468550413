import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['result'];
  }

  initialize() {
    this.props = {
      resultTimeoutId: null,
    };
  }

  showResult() {
    if (this.resultTimeoutId) {
      clearTimeout(this.resultTimeoutId);
    }

    this.resultTarget.removeAttribute('hidden');

    this.resultTimeoutId = setTimeout(() => {
      this.resultTimeoutId = null;
      this.resultTarget.setAttribute('hidden', 'hidden');
    }, 3000);
  }

  triggerCopied() {
    this.dispatch('copied');
  }

  copyText(text) {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }

    const body = document.body;

    if (!body) {
      return Promise.reject(new Error());
    }

    const node = this.createNode(text);
    body.appendChild(node);
    this.copyNode(node);
    body.removeChild(node);

    return Promise.resolve();
  }

  copyNode(node) {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(node.textContent || '');
    }

    const selection = getSelection();

    if (selection == null) {
      return Promise.reject(new Error());
    }

    selection.removeAllRanges();

    const range = document.createRange();
    range.selectNodeContents(node);
    selection.addRange(range);

    document.execCommand('copy');
    selection.removeAllRanges();

    return Promise.resolve();
  }

  copyTarget(node) {
    if (node instanceof HTMLInputElement || node instanceof HTMLTextAreaElement) {
      return this.copyText(node.value);
    }

    if (node instanceof HTMLAnchorElement && node.hasAttribute('href')) {
      return this.copyText(node.href);
    }

    return this.copyNode(node);
  }

  createNode(text) {
    const node = document.createElement('pre');

    node.style.width = '1px';
    node.style.height = '1px';
    node.style.position = 'fixed';
    node.style.top = '5px';
    node.textContent = text;

    return node;
  }

  async copy(event) {
    event.preventDefault();

    if (this.value) {
      await this.copyText(this.value);
      this.showResult();
      this.triggerCopied();
      return;
    }

    if (this.id) {
      const root = 'getRootNode' in Element.prototype ? this.element.getRootNode() : this.element.ownerDocument;

      if (!(root instanceof Document || ('ShadowRoot' in window && root instanceof ShadowRoot))) {
        return;
      }

      const node = root.getElementById(this.id);

      if (node) {
        await this.copyTarget(node);
        this.showResult();
        this.triggerCopied();
      }
    }
  }

  get value() {
    return this.element.dataset.value;
  }

  get id() {
    return this.element.dataset.forId;
  }

  get resultTimeoutId() {
    return this.props.resultTimeoutId;
  }

  set resultTimeoutId(value) {
    this.props.resultTimeoutId = value;
  }
}
