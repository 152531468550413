import { editorViewCtx } from '@milkdown/core';
import { linkSchema } from '@milkdown/preset-commonmark';

import { findMarkPosition } from '../utils';
import { linkTooltipAPICtx } from './config';

export const toggleLinkWithTooltipAction = (ctx, { removeWhenExists = false } = {}) => {
  const editorView = ctx.get(editorViewCtx);
  const actions = ctx.get(linkTooltipAPICtx.key);

  const {
    doc,
    selection: { $from, from, to, empty },
  } = editorView.state;

  const node = doc.nodeAt(from);
  const mark = node?.marks?.find((m) => m.type === linkSchema.mark.type(ctx));

  if (mark) {
    const markPosition = findMarkPosition(mark, doc, $from.before(), $from.after());

    if (removeWhenExists) {
      actions.removeLink(markPosition.start, markPosition.end);
    } else {
      actions.editLink(mark, markPosition.start, markPosition.end);
    }

    return;
  }

  if (empty || from === to) {
    return;
  }

  actions.addLink(from, to);
};

export default {
  toggleLinkWithTooltipAction,
};
