import { linkPreviewTooltip } from '../tooltips';
import LinkPreviewTooltip from './view';

export default function configureLinkPreviewTooltip(ctx) {
  let linkPreviewTooltipView;

  const onClick = (view, event) => {
    if (!event.metaKey && !event.altKey) return;
    if (event.target.nodeName !== 'A' || event.target.href === '') return;

    event.preventDefault();
    event.stopPropagation();

    window.open(event.target.href, '_blank', 'noopener, noreferrer');
  };

  ctx.set(linkPreviewTooltip.key, {
    props: {
      handleDOMEvents: {
        click: onClick,
      },
    },
    view: (view) => {
      linkPreviewTooltipView = new LinkPreviewTooltip(ctx, view);

      return linkPreviewTooltipView;
    },
  });
}
