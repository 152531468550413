import { posToDOMRect } from '@milkdown/prose';
import stimulus, { FUSE_TOOLTIP_CONTROLLER } from 'plugins/stimulus';
import addListener from 'plugins/utilities/add_listener';
import debounce from 'plugins/utilities/debounce';
import removeListener from 'plugins/utilities/remove_listener';

export const DEFAULT_TOOLTIP_OPTIONS = {
  disableAnimation: true,
  toggleManually: true,
  interactive: true,
  hideArrow: true,
  useHideMiddleware: true,
  hideStrategy: 'referenceHidden',
  placement: 'top',
  classes: 'tw-p-2 tw-rounded-lg tw-bg-semidark tw-border tw-border-white/10',
};

export default class TooltipProvider {
  constructor(options) {
    this.setEditorAsBoundary = options.setEditorAsBoundary ?? true;
    this.content = options.content;
    this.debounce = options.debounce ?? 200;
    this.shouldShow = options.shouldShow ?? this._shouldShow;
    this.tooltipOptions = { ...DEFAULT_TOOLTIP_OPTIONS, ...(options.tooltipOptions ?? {}) };
    this.listeners = [];
    this.callbacks = {};
    this.element = null;
    this.tooltipController = null;
    this.updater = null;
  }

  initializeTooltip(view) {
    if (this.isTooltipInitialized) return;

    const element = document.createElement('div');
    element.className = 'tw-w-0 tw-h-0 tw-overflow-hidden';

    view.dom.insertAdjacentElement('afterend', element);

    addListener(element, `${FUSE_TOOLTIP_CONTROLLER}:connected`, (event) => {
      if (element !== event.target) {
        event.stopPropagation();
        return;
      }

      const {
        detail: { controller },
      } = event;

      this.element = element;
      this.tooltipController = controller;

      this.tooltipController.itemProps(this.element).resolvedContent = this.content;
      this.tooltipController.itemProps(this.element).appendTo = element.parentElement;

      if (this.setEditorAsBoundary) {
        this.tooltipController.itemProps(this.element).boundary = element.parentElement.parentElement;
      }

      this.updateTooltipVirtualElementFromView(view);
      this.toggleShowHide(view);

      this.runCallback('connected');
    });

    this.listeners.push(
      {
        target: element,
        id: addListener(element, `${FUSE_TOOLTIP_CONTROLLER}:show`, (event) => {
          if (element !== event.target) {
            event.stopPropagation();
            return;
          }

          this.runCallback('show');
        }),
      },
      {
        target: element,
        id: addListener(element, `${FUSE_TOOLTIP_CONTROLLER}:hide`, (event) => {
          if (element !== event.target) {
            event.stopPropagation();
            return;
          }

          this.runCallback('hide');
        }),
      },
    );

    stimulus.set(element, {
      controllerDataValue: { [FUSE_TOOLTIP_CONTROLLER]: this.tooltipOptions },
      target: { [FUSE_TOOLTIP_CONTROLLER]: 'item' },
    });
  }

  toggleShowHide(view, prevState) {
    if (!this.shouldShow(view, prevState)) {
      this.hide();
      return;
    }

    this.show();
  }

  updateTooltipBoundary(boundary) {
    this.tooltipController.itemProps(this.element).boundary = boundary;
  }

  updateTooltipVirtualElementCustomPosition(view, from, to) {
    if (!this.tooltipController) return;

    let endPositionOverride = {};
    const endToEndWidth = posToDOMRect(view, from, to).width;

    for (let i = to - 1; i >= from; i--) {
      const tmpRect = posToDOMRect(view, from, i);

      if (tmpRect.width > endToEndWidth) {
        endPositionOverride = { right: tmpRect.right, width: tmpRect.width };
        break;
      }
    }

    this.tooltipController.itemProps(this.element).virtualElement = {
      getBoundingClientRect: () => ({ ...posToDOMRect(view, from, to), ...endPositionOverride }),
    };
  }

  updateTooltipVirtualElementFromView(view) {
    if (!this.tooltipController) return;

    const { state } = view;
    const { selection } = state;
    const { ranges } = selection;
    const from = Math.min(...ranges.map((range) => range.$from.pos));
    const to = Math.max(...ranges.map((range) => range.$to.pos));

    this.updateTooltipVirtualElementCustomPosition(view, from, to);
  }

  _shouldShow(view) {
    const { doc, selection } = view.state;
    const { empty, from, to } = selection;

    const isEmptyTextBlock = !doc.textBetween(from, to).length && view.state.selection;

    const isTooltipChildren = this.content.contains(document.activeElement);

    const notHasFocus = !view.hasFocus() && !isTooltipChildren;

    const isReadonly = !view.editable;

    if (notHasFocus || empty || isEmptyTextBlock || isReadonly) {
      return false;
    }

    return true;
  }

  // Update provider state by editor view.
  update(view, prevState) {
    this.initializeTooltip(view);

    this.updater = debounce(this.debouncedUpdate.bind(this), this.debounce);
    this.updater(view, prevState);
  }

  debouncedUpdate(view, prevState) {
    this.updater = null;

    const { state, composing } = view;
    const { selection, doc } = state;
    const isSame = prevState && prevState.doc.eq(doc) && prevState.selection.eq(selection);

    this.updateTooltipVirtualElementFromView(view);
    if (composing || isSame || !this.isTooltipInitialized) return;

    this.toggleShowHide(view, prevState);
  }

  runCallback(name, ...attrs) {
    for (const callback of this.callbacks[name] || []) {
      callback(...attrs);
    }
  }

  on(name, callback) {
    if (!this.callbacks[name]) {
      this.callbacks[name] = [];
    }

    this.callbacks[name].push(callback);
  }

  destroy() {
    for (const { id, target } of this.listeners) {
      removeListener(target, { id });
    }

    if (this.updater) {
      this.updater.cancel();
      this.updater = null;
    }

    if (!this.isTooltipInitialized) return;

    stimulus.setTarget(this.element, { [FUSE_TOOLTIP_CONTROLLER]: '' });
  }

  show() {
    if (!this.isTooltipInitialized || this.isVisible) return;

    this.tooltipController.itemValues(this.element).show = true;
  }

  hide() {
    if (!this.isTooltipInitialized || !this.isVisible) return;

    this.tooltipController.itemValues(this.element).show = false;
  }

  get isVisible() {
    return this.tooltipController.itemValues(this.element).show;
  }

  get isTooltipInitialized() {
    return !!this.element;
  }
}
