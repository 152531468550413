import ApplicationController from 'modules/application_controller';
import remove from 'plugins/element/remove';

export default class extends ApplicationController {
  connect() {
    const modalContentController = this.findParentControllerByName(this.modalComponentIdentifier, {
      hideWarning: true,
    });

    if (this.action === 'accept') {
      modalContentController.acceptModal(true);
    } else {
      modalContentController.rejectModal(true);
    }

    remove(this.element);
  }

  get action() {
    return this.element.dataset.action;
  }

  get modalComponentIdentifier() {
    return this.element.dataset.modalComponentIdentifier;
  }
}
