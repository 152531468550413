import { editorViewCtx } from '@milkdown/core';

import { TooltipProvider } from '../../tooltip_plugin';
import { linkTooltipAPICtx, linkTooltipConfigCtx, linkTooltipMarkCtx, linkTooltipModeCtx } from '../config';
import { findCurrentLinkMarkAndPosition } from '../utils';

import LinkPreviewComponent from './component';

export default class LinkPreviewTooltip {
  constructor(ctx, view) {
    this.ctx = ctx;
    this.view = view;

    this.content = new LinkPreviewComponent(this.ctx.get(linkTooltipConfigCtx.key));
    this.provider = new TooltipProvider({
      debounce: 0,
      content: this.content.domElement,
      shouldShow: () => false,
      tooltipOptions: {
        disableAnimation: true,
        invisibleInsteadOfHide: true,
      },
    });

    this.provider.update(view);
    this.ctx.use(linkTooltipModeCtx.key).on(this.onModeChange.bind(this));

    this.content.on('edit', () => {
      this.ctx.get(linkTooltipAPICtx.key).editLink();
    });

    this.content.on('remove', () => {
      this.ctx.get(linkTooltipAPICtx.key).removeLink();
      this.hide({ reset: true });
    });

    this.provider.on('hide', () => {
      if (!view.hasFocus()) {
        this.ctx.get(editorViewCtx).dom.focus();
      }
    });
  }

  onModeChange({ mode }) {
    if (mode === 'edit') {
      this.hide({ reset: false });
    } else if (mode === 'preview') {
      this.update(this.view);
    }
  }

  show(view, mark, from, to) {
    const config = this.ctx.get(linkTooltipConfigCtx.key);

    this.content.config = config;
    this.content.href = mark.attrs.href;

    this.ctx.update(linkTooltipMarkCtx.key, () => ({ mark, from, to }));

    this.provider.updateTooltipVirtualElementCustomPosition(view, from, to);
    this.provider.show();
  }

  hide({ reset = false } = {}) {
    this.provider.hide();

    if (reset) {
      this.ctx.update(linkTooltipMarkCtx.key, () => ({ mark: null, from: -1, to: -1 }));
    }
  }

  update(view, prevState) {
    if (prevState && prevState.selection.eq(view.state.selection)) return;

    const { mode } = this.ctx.get(linkTooltipModeCtx.key);

    if (mode !== 'preview') return;

    const { mark: activeMark } = this.ctx.get(linkTooltipMarkCtx.key);
    const { mark, from, to } = findCurrentLinkMarkAndPosition(this.ctx, view);

    if (activeMark === mark) return;

    if (mark) {
      this.show(view, mark, from, to);
    } else if (!mark && this.provider.isVisible) {
      this.hide({ reset: true });
    }
  }

  destroy() {
    // this.ctx.use(linkTooltipStateCtx.key).off(this.onStateChange);
    this.provider.destroy();
    this.content.remove();
  }
}
