import { linkTooltipAPICtx, linkTooltipConfigCtx, linkTooltipMarkCtx, linkTooltipModeCtx } from './config';
import configureLinkEditTooltip from './edit/config';
import configureLinkPreviewTooltip from './preview/config';
import { linkEditTooltip, linkPreviewTooltip } from './tooltips';

export * from './actions';
export * from './config';
export * from './tooltips';

function configureLinkTooltip(ctx) {
  configureLinkPreviewTooltip(ctx);
  configureLinkEditTooltip(ctx);
}

export const linkTooltipPlugin = [
  linkTooltipMarkCtx,
  linkTooltipModeCtx,
  linkTooltipAPICtx,
  linkTooltipConfigCtx,
  linkPreviewTooltip,
  linkEditTooltip,
  configureLinkTooltip,
];
