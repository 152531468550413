import createElementFromHTML from 'plugins/element/create_element_from_html';
import addListener from 'plugins/utilities/add_listener';
import removeListener from 'plugins/utilities/remove_listener';

export default class ListItemBlockComponent {
  constructor(config, attrs) {
    this.data = { config, attrs };
    this.elements = {
      dom: this.renderDom(),
      content: this.renderContent(),
      marker: this.renderMarker(),
    };
    this.callbacks = {};
    this.listeners = [];

    this.setMarkerType();
    this.initializeListeners();

    this.domElement.appendChild(this.contentElement);
  }

  initializeListeners() {
    this.listeners.push({
      target: this.markerElement,
      id: addListener(this.markerElement, 'change', this.onChecked.bind(this)),
    });
  }

  renderDom() {
    return createElementFromHTML('<li class="tw-relative"></li>');
  }

  renderContent() {
    return createElementFromHTML('<div></div>');
  }

  renderMarker() {
    return createElementFromHTML('<span data-marker="true"><input type="checkbox"></span>');
  }

  onChecked({ target }) {
    this.runCallback('checkedChanged', target.checked);
  }

  setMarkerType() {
    const { checked = null } = this.attrs;

    if (checked === null) {
      this.markerElement.remove();

      return;
    }

    (this.markerElement.querySelector('input') || this.markerElement).checked = checked;
    this.domElement.insertAdjacentElement('afterbegin', this.markerElement);
  }

  runCallback(name, ...attrs) {
    for (const callback of this.callbacks[name] || []) {
      callback(...attrs);
    }
  }

  on(name, callback) {
    if (!this.callbacks[name]) {
      this.callbacks[name] = [];
    }

    this.callbacks[name].push(callback);
  }

  remove() {
    for (const { target, id } of this.listeners) {
      removeListener(target, { id });
    }

    this.domElement.remove();
  }

  getId(suffix) {
    return `${this.config.id}_${suffix}`;
  }

  set attrs(value) {
    const oldAttrsToCheck = { ...this.attrs, checked: this.attrs.checked !== null ? null : undefined };
    const newAttrsToCheck = { ...value, checked: value.checked !== null ? null : undefined };
    const rerenderMarker = JSON.stringify(oldAttrsToCheck) !== JSON.stringify(newAttrsToCheck);

    this.data.attrs = value;

    if (rerenderMarker) {
      this.setMarkerType();
    } else if (value.checked !== null) {
      (this.markerElement.querySelector('input') || this.markerElement).checked = value.checked;
    }
  }

  get attrs() {
    return this.data.attrs;
  }

  set config(value) {
    const rerenderMarker = JSON.stringify(this.config) !== JSON.stringify(value);

    this.data.config = value;

    if (rerenderMarker) {
      this.setMarkerType();
    }
  }

  get config() {
    return this.data.config;
  }

  get domElement() {
    return this.elements.dom;
  }

  get contentElement() {
    return this.elements.content;
  }

  get markerElement() {
    return this.elements.marker;
  }
}
