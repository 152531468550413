import '@/fuse/javascript/i18n';
import initializeTurbo from 'integration/turbo/initialize_turbo';
import registerInputFields from 'modules/input_fields/register_input_fields';
import registerModal from 'modules/modal/register_modal';

import './partials/_fuse_base';
import './partials/_fuse_components';
import './partials/_fuse_stimulus_register';
import './partials/_polyfills';
import './partials/_vendor_fuse';

initializeTurbo();
registerModal();
registerInputFields();
