import { linkSchema } from '@milkdown/preset-commonmark';

export function findCurrentLinkMarkAndPosition(ctx, view) {
  const { state } = view;
  const { selection } = state;

  const { from, to } = selection;
  let linkMarkAndPosition = { mark: null, from, to };

  state.doc.nodesBetween(from, to, (node, pos) => {
    if (node.marks.length > 0) {
      for (const mark of node.marks) {
        if (mark.type !== linkSchema.mark.type(ctx)) continue;

        linkMarkAndPosition = { mark, from: pos, to: pos + node.nodeSize };
        break;
      }
    }
  });

  return linkMarkAndPosition;
}

export default {
  findCurrentLinkMarkAndPosition,
};
