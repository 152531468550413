import { linkTooltipAPICtx } from '../config';
import { linkEditTooltip } from '../tooltips';
import LinkEditTooltip from './view';

export default function configureLinkEditTooltip(ctx) {
  let linkEditTooltipView;

  ctx.update(linkTooltipAPICtx.key, (api) => ({
    ...api,
    addLink: (from, to) => {
      linkEditTooltipView?.addLink(from, to);
    },
    editLink: (mark, from, to) => {
      linkEditTooltipView?.editLink(mark, from, to);
    },
    removeLink: (from, to) => {
      linkEditTooltipView?.removeLink(from, to);
    },
  }));

  ctx.set(linkEditTooltip.key, {
    view: (view) => {
      linkEditTooltipView = new LinkEditTooltip(ctx, view);

      return linkEditTooltipView;
    },
  });
}
