import { linkSchema } from '@milkdown/preset-commonmark';

const menuLinkButtonEmptyAvailability = (buttons, ctx, view) => {
  const {
    state: {
      doc,
      selection: { head, from, to, empty },
    },
  } = view;
  const isEmpty = empty || from === to;
  let disabled = isEmpty;

  const node = doc.nodeAt(head);

  if (node) {
    const mark = node.marks.find((m) => m.type === linkSchema.mark.type(ctx));
    disabled = isEmpty && !mark;
  }

  for (const button of buttons) {
    button.disabled = disabled;
  }
};

export default menuLinkButtonEmptyAvailability;
