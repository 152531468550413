import 'choices.js/public/assets/styles/choices.css';
import FormFieldBaseController from 'fuse/form_field_base_component/form_field_base_component';

import './form_field_component.module.css';

export default class extends FormFieldBaseController {
  static get targets() {
    return [...super.targets, 'inputWrapper'];
  }

  static get classes() {
    return [...super.classes, 'choices', 'mobileFocusHack'];
  }

  initialize() {
    super.initialize();

    this.observer = new MutationObserver(this.handleMutations.bind(this));
  }

  connect() {
    super.connect();

    if (!this.hasInputTarget) return;

    this.initArgumentsObserver();
    this.initChoicesClasses();
    this.addMobileFocusHackClasses();

    if (this.inputWrapperTarget.contains(document.activeElement)) {
      this.removeMobileFocusHackClasses({ target: this.inputTarget });
    }
  }

  disconnect() {
    super.disconnect();

    this.observer.disconnect();
  }

  initArgumentsObserver() {
    this.updateDisabled(this.inputTarget);

    this.observer.observe(this.inputTarget, { attributes: true });
  }

  initChoicesClasses() {
    if (!this.hasChoicesClass) return;

    this.bodyTarget.classList.add(...this.choicesClasses);
  }

  handleMutations(mutations) {
    for (const { type, attributeName, target } of mutations) {
      if (type !== 'attributes') continue;

      switch (attributeName) {
        case 'disabled':
          this.updateDisabled(target);

          break;
        default:
          break;
      }
    }
  }

  updateDisabled(target) {
    if (target.getAttribute('readonly') === 'readonly') return;

    const value = target.disabled;
    this.element.classList.toggle('disabled', value);
  }

  addMobileFocusHackClasses() {
    this.inputWrapperTarget.classList.add(...this.mobileFocusHackClasses);
  }

  removeMobileFocusHackClasses({ target }) {
    if (![...this.inputWrapperTarget.lastElementChild.children].includes(target)) return;

    this.inputWrapperTarget.classList.remove(...this.mobileFocusHackClasses);
  }
}
