function setImgSrcFromFile(img, file) {
  const reader = new FileReader();

  reader.onload = (e) => {
    img.src = e.target.result;
    img.hidden = false;
  };

  reader.readAsDataURL(file);
}

export default setImgSrcFromFile;
