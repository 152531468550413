import createElementFromHTML from 'plugins/element/create_element_from_html';

export default class SlashMenuComponent {
  constructor(config) {
    this.data = { config, buttons: null, selectedIndex: 0 };
    this.elements = {
      dom: this.renderDom(),
    };
    this.callbacks = {};
  }

  renderDom({ contentHtml } = this.config) {
    return createElementFromHTML(contentHtml);
  }

  reset() {
    this.buttons[this.selectedIndex].classList.remove('selected');
    this.data.selectedIndex = 0;
  }

  focus() {
    this.selectedIndex = 0;
  }

  moveUp() {
    this.selectedIndex--;
  }

  moveDown() {
    this.selectedIndex++;
  }

  triggerClick() {
    this.buttons[this.selectedIndex].click();
    this.hide();
  }

  hide() {
    this.runCallback('hide');
  }

  handleKeyDown(event) {
    const { key } = event;

    if (!Object.keys(this.keyboardShortcuts).includes(key)) return;

    event.preventDefault();
    event.stopPropagation();

    this.keyboardShortcuts[key]();
  }

  runCallback(name, ...attrs) {
    for (const callback of this.callbacks[name] || []) {
      callback(...attrs);
    }
  }

  on(name, callback) {
    if (!this.callbacks[name]) {
      this.callbacks[name] = [];
    }

    this.callbacks[name].push(callback);
  }

  remove() {
    this.domElement.remove();
  }

  get domElement() {
    return this.elements.dom;
  }

  get config() {
    return this.data.config;
  }

  get buttons() {
    if (!this.data.buttons) {
      this.data.buttons = [...this.domElement.children].filter((child) => child.tagName === 'BUTTON');
    }

    return this.data.buttons;
  }

  set buttons(value) {
    this.data.buttons = value;
  }

  get selectedIndex() {
    return this.data.selectedIndex;
  }

  set selectedIndex(value) {
    this.buttons[this.selectedIndex].classList.remove('selected');

    const increaseBy = value < this.selectedIndex ? -1 : 1;
    let normalizedIndex = value % this.buttons.length;
    let newIndex = normalizedIndex < 0 ? this.buttons.length + normalizedIndex : normalizedIndex;

    while (
      this.buttons[newIndex].hidden ||
      this.buttons[newIndex].disabled ||
      this.buttons[newIndex].classList.contains('disabled') ||
      window.getComputedStyle(this.buttons[newIndex]).display === 'none'
    ) {
      normalizedIndex = (newIndex + increaseBy) % this.buttons.length;
      newIndex = normalizedIndex < 0 ? this.buttons.length + normalizedIndex : normalizedIndex;
    }

    this.data.selectedIndex = newIndex;
    this.buttons[this.selectedIndex].classList.add('selected');
  }

  get keyboardShortcuts() {
    return {
      ArrowDown: this.moveDown.bind(this),
      ArrowUp: this.moveUp.bind(this),
      Enter: this.triggerClick.bind(this),
      Escape: this.hide.bind(this),
    };
  }
}
