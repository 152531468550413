import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['input', 'cents'];
  }

  static get values() {
    return {
      keepCentsEmpty: {
        type: Boolean,
        default: false,
      },
      keepWholeCents: {
        type: Boolean,
        default: false,
      },
    };
  }

  initialize() {
    this.centsValueObserver = new MutationObserver(this.valueChangeObserver.bind(this));
    this.skipFireChange = false;
  }

  connect() {
    this.skipFireChange = true;
    this.updateHiddenCents();

    if (this.hasCentsTarget) {
      this.centsValueObserver.observe(this.centsTarget, { attributeFilter: ['value'] });
    }
  }

  disconnect() {
    this.centsValueObserver.disconnect();
  }

  valueChangeObserver(mutationList) {
    for (const mutation of mutationList) {
      switch (mutation.type) {
        case 'attributes':
          switch (mutation.attributeName) {
            case 'value':
              this.updateAltInputFromHiddenCents();
              break;
            default:
          }
          break;
        default:
      }
    }
  }

  updateHiddenCents(event = null) {
    if (!this.hasCentsTarget) return;

    const inputValue = this.priceFromTarget(this.inputTarget);
    let centsValue = '';

    if (this.inputTarget.value !== '' || !this.keepCentsEmptyValue) {
      centsValue = Math.round(inputValue.toFixed(2) * 100);

      if (event?.type === 'blur' && this.keepWholeCentsValue) {
        this.inputTarget.value = inputValue.toFixed(2);
      }
    }

    const changed = centsValue.toString() !== this.centsTarget.value;

    if (changed) {
      this.centsTarget.value = centsValue;

      if (!this.skipFireChange) {
        this.fireChange();
      }
    }

    this.skipFireChange = false;
  }

  updateAltInputFromHiddenCents() {
    if (!this.hasCentsTarget) return;
    if (document.activeElement === this.inputTarget) return;

    const centsValue = this.priceFromTarget(this.centsTarget);
    const inputValue = centsValue / 100;

    if (!this.skipSettingWholeCents && (this.keepWholeCentsValue || inputValue % 1 !== 0)) {
      this.inputTarget.value = inputValue.toFixed(2);
    } else {
      this.inputTarget.value = inputValue;
    }
  }

  fireChange() {
    this.centsTarget.dispatchEvent(new Event('change', { bubbles: true }));
    this.centsTarget.dispatchEvent(new Event('input', { bubbles: true }));
  }

  priceFromTarget(target) {
    return Number(target.value.replaceAll(',', '.').replace(/[,.]$/, '')) || 0;
  }
}
