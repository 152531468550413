import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['trigger'];
  }

  initialize() {
    this.props = {
      reloadTimeout: null,
    };
  }

  reloadFrame() {
    if (this.reloadTimeout) {
      cancelAnimationFrame(this.reloadTimeout);
      this.reloadTimeout = null;
    }

    if (document.hidden) {
      this.triggerTarget.disabed = true;
      this.triggerTarget.classList.add('disabled');

      this.element.open = false;

      return;
    }

    this.reloadTimeout = requestAnimationFrame(() => {
      this.reloadTimeout = null;

      if (!document.hidden && this.element.parentNode) {
        this.element.parentNode.reload();
      }
    });
  }

  get reloadTimeout() {
    return this.props.reloadTimeout;
  }

  set reloadTimeout(value) {
    this.props.reloadTimeout = value;
  }
}
