// This is actually a copy from package prosemirror-gapcursor with minor changes
// in handling key down event to work with custom code block and table
import { $prose } from '@milkdown/utils';
import { GapCursor } from 'prosemirror-gapcursor';
import { keydownHandler } from 'prosemirror-keymap';
import { Fragment, Slice } from 'prosemirror-model';
import { NodeSelection, Plugin, TextSelection } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

// ------------------------------------------------------------------------------
// This is a hack that, when a composition starts while a gap cursor
// is active, quickly creates an inline context for the composition to
// happen in, to avoid it being aborted by the DOM selection being
// moved into a valid position.
function beforeinput(view, event) {
  if (event.inputType !== 'insertCompositionText' || !(view.state.selection instanceof GapCursor)) return false;
  const { $from } = view.state.selection;
  const insert = $from.parent.contentMatchAt($from.index()).findWrapping(view.state.schema.nodes.text);
  if (!insert) return false;
  let frag = Fragment.empty;
  for (let i = insert.length - 1; i >= 0; i--) frag = Fragment.from(insert[i].createAndFill(null, frag));
  const tr = view.state.tr.replace($from.pos, $from.pos, new Slice(frag, 0, 0));
  tr.setSelection(TextSelection.near(tr.doc.resolve($from.pos + 1)));
  view.dispatch(tr);
  return false;
}

function drawGapCursor(state) {
  if (!(state.selection instanceof GapCursor)) return null;
  const node = document.createElement('div');
  node.className = 'ProseMirror-gapcursor';
  return DecorationSet.create(state.doc, [Decoration.widget(state.selection.head, node, { key: 'gapcursor' })]);
}
// ------------------------------------------------------------------------------

function arrow(axis, dir) {
  let dirStr;

  if (axis === 'vert') {
    dirStr = dir > 0 ? 'down' : 'up';
  } else {
    dirStr = dir > 0 ? 'right' : 'left';
  }

  return function (state, dispatch, view) {
    const sel = state.selection;
    let $start = dir > 0 ? sel.$to : sel.$from;
    let mustMove = sel.empty;
    if (sel instanceof TextSelection) {
      if (!view.endOfTextblock(dirStr) || $start.depth === 0) return false;
      mustMove = false;
      $start = state.doc.resolve(dir > 0 ? $start.after() : $start.before());
    }
    let $found = GapCursor.findGapCursorFrom($start, dir, mustMove);
    if (!$found) {
      const endOfStartNode = $start.doc.type.name === 'doc' && (dir > 0 ? $start.end() : $start.start()) === $start.pos;
      if (!endOfStartNode) {
        return false;
      }
      const siblingNode = dir > 0 ? $start.nodeBefore : $start.nodeAfter;
      if (!siblingNode || !['code_block', 'table'].includes(siblingNode.type.name)) {
        return false;
      }
      $found = $start;
    }
    if (dispatch) dispatch(state.tr.setSelection(new GapCursor($found)));
    return true;
  };
}

const handleKeyDown = keydownHandler({
  ArrowLeft: arrow('horiz', -1),
  ArrowRight: arrow('horiz', 1),
  ArrowUp: arrow('vert', -1),
  ArrowDown: arrow('vert', 1),
});

function handleClick(view, pos, event) {
  if (!view || !view.editable) return false;
  const $pos = view.state.doc.resolve(pos);
  if (!GapCursor.valid($pos)) {
    const isBefore = $pos.start() === $pos.pos;
    const isAfter = $pos.end() === $pos.pos;
    if ($pos.doc.type.name === 'doc' && (isBefore || isAfter)) {
      const siblingNode = isBefore ? $pos.nodeAfter : $pos.nodeBefore;
      if (!siblingNode || !['code_block', 'table'].includes(siblingNode.type.name)) {
        return false;
      }
    } else {
      return false;
    }
  }
  const clickPos = view.posAtCoords({ left: event.clientX, top: event.clientY });
  if (clickPos && clickPos.inside > -1 && NodeSelection.isSelectable(view.state.doc.nodeAt(clickPos.inside)))
    return false;
  view.dispatch(view.state.tr.setSelection(new GapCursor($pos)));
  return true;
}

/**
 Create a gap cursor plugin. When enabled, this will capture clicks
 near and arrow-key-motion past places that don't have a normally
 selectable position nearby, and create a gap cursor selection for
 them. The cursor is drawn as an element with class
 `ProseMirror-gapcursor`. You can either include
 `style/gapcursor.css` from the package's directory or add your own
 styles to make it visible.
 */
export function gapCursor() {
  return new Plugin({
    props: {
      decorations: drawGapCursor,
      createSelectionBetween(_view, $anchor, $head) {
        return $anchor.pos === $head.pos && GapCursor.valid($head) ? new GapCursor($head) : null;
      },
      handleClick,
      handleKeyDown,
      handleDOMEvents: { beforeinput },
    },
  });
}

export const gapCursorPlugin = $prose(() => gapCursor());
