import RelativeTimeController from '@/fuse/javascript/relative_time_controller';
import RelativeTime from 'modules/relative_time';

export default class extends RelativeTimeController {
  get formattedDate() {
    if (!this.datetime) {
      return null;
    }

    const relativeTime = new RelativeTime(this.datetime, {
      locale: this.locale,
      precise: this.precise,
      alwaysRelative: true,
      limitElapsed: false,
    });

    if (this.format === 'micro') {
      return relativeTime.microTimeAgo();
    }

    return relativeTime.toString();
  }

  get format() {
    return this.element.dataset.format;
  }
}
