import getData from 'plugins/data/get';
import setData from 'plugins/data/set';

function shouldCache({ element }) {
  // skip components inside data-turbo-permanent, their preview and rendered versions are the same and we would break
  // them by altering content in handleRenderPreview; they are already cached so there is no need for our own caching

  return element.dataset.keepCachedContent === 'true' && !element.closest('[data-turbo-permanent]');
}

function isTurboPreview() {
  return document.documentElement.hasAttribute('data-turbo-preview');
}

function store({ key, contentCallback, beforeCacheCallback }) {
  let contentToCache = contentCallback();
  if (!contentToCache) {
    return;
  }

  if (contentToCache instanceof HTMLCollection) {
    contentToCache = Array.from(contentToCache).map((node) => node.cloneNode(true));
  } else if (contentToCache instanceof Node) {
    contentToCache = contentToCache.cloneNode(true);
  } else {
    console.warn('CachedTurboContent: cannot cache unknown content:', contentToCache);
    return;
  }

  if (beforeCacheCallback) {
    contentToCache = beforeCacheCallback(contentToCache);
  }

  setData(global, key, contentToCache);
}

function render({ key, renderCallback }) {
  const cachedContent = getData(window, key);
  setData(global, key, undefined);

  if (!cachedContent) {
    return;
  }

  if (renderCallback) {
    renderCallback(cachedContent);
  }
}

function storeOrRender({ element, key, contentCallback, beforeCacheCallback, renderCallback }) {
  if (!shouldCache({ element })) {
    return;
  }

  key = `${window.location.pathname}:${key}`;

  if (isTurboPreview()) {
    store({ key, contentCallback, beforeCacheCallback });
  } else {
    render({ key, renderCallback });
  }
}

export default { storeOrRender };
