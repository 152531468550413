import ApplicationController from 'modules/application_controller';
import getOffset from 'plugins/element/get_offset';
import scrollTop from 'plugins/utilities/scroll_top';
import viewportHeight from 'plugins/utilities/viewport_height';
import viewportWidth from 'plugins/utilities/viewport_width';

export default class extends ApplicationController {
  static get targets() {
    return ['itemWithPanel', 'itemWithoutPanel', 'panel', 'hiddenInput'];
  }

  initialize() {
    this.props = {
      historyStateTurbo: {},
      currentIndex: -1,
      menuScrollPosition: null,
    };
  }

  connect() {
    this.initSelectedIndex();

    if (this.itemParam === this.currentItemId) {
      this.showPanels(false);
    }

    if (this.isTurboPreview) {
      return;
    }

    this.initHistoryState();
  }

  disconnect() {
    this.showMenu();
  }

  initHistoryState() {
    if (this.disableHistory) {
      return;
    }

    if (window.history.state && window.history.state.turbo) {
      this.historyStateTurbo = { ...window.history.state.turbo };
    }

    const state = this.historyState;
    state.turbo = { ...this.historyStateTurbo };

    window.history.replaceState(state, '', window.location.href);
  }

  initSelectedIndex() {
    this.currentIndex = this.itemWithPanelTargets.indexOf(
      this.itemWithPanelTargets.find((item) => this.isItemSelected(item)),
    );
  }

  handlePopstate(event) {
    if (this.disableHistory) {
      return;
    }

    if (event.state && event.state.nav_id && event.state.nav_id === this.navId) {
      if (this.currentIndex === event.state.item_index) {
        return;
      }

      this.selectItem(event.state.item_index, true);
    }
  }

  initDefaultTab() {
    if (!this.isTurboPreview) {
      return;
    }

    const url = new URL(window.location);
    let initItemIndex = -1;

    if (url.searchParams.has(this.paramName)) {
      initItemIndex = this.panelTargets.findIndex((panel) => panel.id === url.searchParams.get(this.paramName));
    }

    if (initItemIndex < 0) {
      initItemIndex = Math.max(0, this.currentIndex);
    }

    this.selectItem(initItemIndex, true);
  }

  initTurboHistory() {
    if (this.disableHistory) {
      return;
    }

    const state = this.historyState;
    state.turbo = { ...this.historyStateTurbo };

    window.history.replaceState(state, '', window.location.href);
  }

  pushHistory() {
    if (this.disableHistory) {
      return;
    }

    const item = this.currentItemId;
    const url = new URL(window.location);
    url.searchParams.set(this.paramName, item);

    window.history.pushState(this.historyState, '', url);
  }

  switchItemKey(event) {
    if (!this.controlKeys.includes(event.code)) {
      return;
    }

    const target = event.target;

    if (target.getAttribute('role') !== 'tab' && !target.closest('[role="tablist"]')) {
      return;
    }

    let newIndex;

    if (event.code === this.nextKeyCode) {
      event.preventDefault();
      event.stopPropagation();

      newIndex = this.currentIndex + 1;

      if (newIndex >= this.itemWithPanelTargets.length) {
        newIndex = 0;
      }
    }

    if (event.code === this.prevKeyCode) {
      event.preventDefault();
      event.stopPropagation();

      newIndex = this.currentIndex - 1;

      if (newIndex < 0) {
        newIndex = this.itemWithPanelTargets.length - 1;
      }
    }

    if (event.code === 'Home') {
      event.preventDefault();
      event.stopPropagation();

      newIndex = 0;
    }

    if (event.code === 'End') {
      event.preventDefault();
      event.stopPropagation();

      newIndex = this.itemWithPanelTargets.length - 1;
    }

    if (this.currentIndex === newIndex) {
      this.showPanels();
      return;
    }

    this.selectItem(newIndex);
  }

  switchItemClick(event) {
    const target = event.currentTarget;
    const newIndex = this.itemWithPanelTargets.indexOf(target);

    if (target.getAttribute('role') !== 'tab' && !target.closest('[role="tablist"]')) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (this.currentIndex === newIndex) {
      this.showPanels();

      const url = new URL(window.location);

      if (!url.searchParams.has(this.paramName)) {
        this.pushHistory();
      }

      return;
    }

    this.selectItem(newIndex);
  }

  selectItem(index, skipHistory = false) {
    const selectedItem = this.itemWithPanelTargets[index];
    const selectedPanel = this.panelTargets[index];

    const cancelled = !this.dispatch('change', {
      detail: { relatedTarget: selectedPanel },
    });

    if (cancelled) {
      return;
    }

    this.currentIndex = index;

    if (!skipHistory) {
      this.pushHistory();
    }

    for (const item of this.itemWithPanelTargets) {
      this.updateUnselectedItem(item);
    }

    for (const panel of this.panelTargets) {
      panel.hidden = true;
    }

    this.updateSelectedItem(selectedItem);
    selectedItem.focus();
    selectedPanel.hidden = false;
    this.showPanels();

    if (this.setHiddenInput) {
      this.hiddenInputTarget.name = this.paramName;
      this.hiddenInputTarget.value = this.currentItemId;
    }

    this.dispatch('changed', {
      detail: { relatedTarget: selectedPanel },
      cancelable: false,
    });
  }

  selectByLocation() {
    const path = `${window.location.origin}${window.location.pathname}`;

    for (const it of this.itemWithPanelTargets) {
      if (it.href === path) {
        this.updateSelectedItem(it);
      } else {
        this.updateUnselectedItem(it);
      }
    }

    for (const it of this.itemWithoutPanelTargets) {
      if (it.href === path) {
        this.updateSelectedItem(it);
      } else {
        this.updateUnselectedItem(it);
      }
    }
  }

  showPanels(scrollIntoView = true) {
    this.menuScrollPosition = scrollTop();
    this.element.classList.add('panel-active');

    if (this.direction !== 'vertical' || !scrollIntoView) {
      return;
    }

    const panelTarget = this.panelTargets[this.currentIndex];

    const currentScrollTop = scrollTop();
    const topOffset = getOffset('top', panelTarget, true);
    const headerOffset = viewportWidth() >= 768 ? 68 : 8;
    const targetScrollTop = topOffset - headerOffset;

    if (targetScrollTop < currentScrollTop || targetScrollTop > currentScrollTop + viewportHeight()) {
      scrollTop({ value: targetScrollTop });
    }
  }

  showMenu() {
    this.element.classList.remove('panel-active');

    if (this.direction === 'vertical') {
      scrollTop({ value: this.menuScrollPosition });
    }
  }

  isItemAnchor(item) {
    return item.nodeName === 'A';
  }

  isItemSelected(item) {
    if (this.isItemAnchor(item)) {
      const ariaCurrent = item.getAttribute('aria-current');
      return ariaCurrent === 'true' || ariaCurrent === 'page';
    }

    return item.getAttribute('aria-selected') === 'true';
  }

  updateUnselectedItem(item) {
    if (this.isItemAnchor(item)) {
      item.setAttribute('aria-current', false);
      return;
    }

    item.setAttribute('aria-selected', false);
  }

  updateSelectedItem(item) {
    if (this.isItemAnchor(item)) {
      item.setAttribute('aria-current', true);
      return;
    }

    item.setAttribute('aria-selected', true);
  }

  get itemParam() {
    const url = new URL(window.location);

    return url.searchParams.get(this.paramName);
  }

  get navId() {
    return this.element.id;
  }

  get historyState() {
    const state = {
      nav_id: this.navId,
      item_index: this.currentIndex,
    };

    return state;
  }

  get currentIndex() {
    return this.props.currentIndex;
  }

  set currentIndex(value) {
    this.props.currentIndex = value;
  }

  get currentItemId() {
    if (this.currentIndex < 0) {
      return null;
    }

    return this.panelTargets[this.currentIndex].id;
  }

  get historyStateTurbo() {
    return this.props.historyStateTurbo;
  }

  set historyStateTurbo(value) {
    this.props.historyStateTurbo = value;
  }

  get menuScrollPosition() {
    return this.props.menuScrollPosition;
  }

  set menuScrollPosition(value) {
    this.props.menuScrollPosition = value;
  }

  get disableHistory() {
    return this.element.dataset.disableHistory === 'true';
  }

  get controlKeys() {
    return [this.prevKeyCode, this.nextKeyCode, 'Home', 'End'];
  }

  get prevKeyCode() {
    if (this.direction === 'vertical') {
      return 'ArrowUp';
    }

    return 'ArrowLeft';
  }

  get nextKeyCode() {
    if (this.direction === 'vertical') {
      return 'ArrowDown';
    }

    return 'ArrowRight';
  }

  get direction() {
    return this.element.dataset.direction || 'horizontal';
  }

  get paramName() {
    return this.element.dataset.paramName;
  }

  get setHiddenInput() {
    return this.element.dataset.setHiddenInput === 'true';
  }
}
