import ApplicationController from 'modules/application_controller';
import CachedTurboContent from 'modules/cached_turbo_content';

export default class extends ApplicationController {
  initialize() {
    this.missingCount = 0;
  }

  connect() {
    this.missingCount = 0;
  }

  reload() {
    if (!this.element.src) {
      return;
    }

    this.element.reload();
  }

  // ToDo: This is a hotfix for a bug where the initial request might be sent without cookie header
  // in some browsers. Issue in turbo repository raised, waiting for any comment.
  triggerReloadOnMissing(event) {
    if (this.missingCount > 3) return;

    this.missingCount++;

    event.preventDefault();
    setTimeout(this.reload.bind(this), this.missingCount * 100);
  }

  storeOrRenderCachedTurboContent() {
    CachedTurboContent.storeOrRender({
      element: this.element,
      key: `turboFrameCache#${this.element.id}`,
      contentCallback: () => this.element.children,
      renderCallback: (content) => {
        this.element.innerHTML = '';

        for (const element of content) {
          this.element.insertAdjacentElement('beforeend', element);
        }
      },
    });
  }
}
