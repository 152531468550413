import createElementFromHTML from 'plugins/element/create_element_from_html';
import addListener from 'plugins/utilities/add_listener';
import generateRandomId from 'plugins/utilities/generate_random_id';
import removeListener from 'plugins/utilities/remove_listener';

export default class TableSelectorWidgetComponent {
  constructor(attrs) {
    this.attrs = attrs;
    this.data = { dragOver: false };
    this.elements = {
      dom: this.renderDom(),
    };
    this.callbacks = {};
    this.listeners = [];
    this.id = generateRandomId(10);

    if (this.type !== 'top-left') {
      this.domElement.draggable = true;
    }

    this.updateType();
    this.initializeListeners();
  }

  initializeListeners() {
    this.removeListeners();

    this.listeners.push(
      {
        target: this.domElement,
        id: addListener(this.domElement, 'click', (event) => {
          event.stopPropagation();
          this.runCallback('click', this.domElement, this.attrs);
        }),
      },
      {
        target: this.domElement,
        id: addListener(this.domElement, 'dragstart', (event) => {
          event.stopPropagation();

          event.dataTransfer.setData('application/milkdown-table-sort', JSON.stringify(this.attrs));
          event.dataTransfer.effectAllowed = 'move';
        }),
      },
      {
        target: this.domElement,
        id: addListener(this.domElement, 'dragover', (event) => {
          event.stopPropagation();
          event.preventDefault();

          this.dragOver = true;

          event.dataTransfer.dropEffect = 'move';
        }),
      },
      {
        target: this.domElement,
        id: addListener(this.domElement, 'dragleave', () => {
          this.dragOver = false;
        }),
      },
      {
        target: this.domElement,
        id: addListener(this.domElement, 'drop', (event) => {
          this.dragOver = false;

          if (this.type === 'top-left') return;

          if (this.index === null) return;

          const data = JSON.parse(event.dataTransfer.getData('application/milkdown-table-sort'));

          this.runCallback('drop', this.attrs, data);
        }),
      },
    );
  }

  removeListeners() {
    for (const { target, id } of this.listeners) {
      removeListener(target, { id });
    }

    this.listeners = [];
  }

  renderDom() {
    return createElementFromHTML(
      '<div class="tw-absolute tw-cursor-pointer tw-bg-primary/25 hover:tw-bg-primary-hover/38"></div>',
    );
  }

  updateType() {
    if (this.type === 'left') {
      this.domElement.classList.remove(...this.topClasses, ...this.topLeftClasses);
      this.domElement.classList.add(...this.leftClasses);
      return;
    }

    if (this.type === 'top') {
      this.domElement.classList.remove(...this.leftClasses, ...this.topLeftClasses);
      this.domElement.classList.add(...this.topClasses);
      return;
    }

    this.domElement.classList.remove(...this.leftClasses, ...this.topClasses);
    this.domElement.classList.add(...this.topLeftClasses);

    this.domElement.draggable = this.type !== 'top-left';
  }

  runCallback(name, ...attrs) {
    for (const callback of this.callbacks[name] || []) {
      callback(...attrs);
    }
  }

  on(name, callback) {
    if (!this.callbacks[name]) {
      this.callbacks[name] = [];
    }

    this.callbacks[name].push(callback);
  }

  remove() {
    this.removeListeners();
    this.domElement.remove();
  }

  get dragOver() {
    return this.data.dragOver;
  }

  set dragOver(value) {
    this.domElement.classList.toggle('tw-ring-2', value);

    this.data.dragOver = value;
  }

  get domElement() {
    return this.elements.dom;
  }

  get type() {
    return this.attrs.type;
  }

  get index() {
    return this.attrs.index;
  }

  get leftClasses() {
    return ['tw-w-2', 'tw--left-3.5', 'tw-top-0', 'tw-bottom-px', '[tr:first-child>*>&]:tw-bottom-4'];
  }

  get topClasses() {
    return ['tw-right-px', 'tw-h-2', 'tw-left-0', 'tw--top-3.5'];
  }

  get topLeftClasses() {
    return ['tw-h-3', 'tw-w-3', 'tw--left-4', 'tw--top-4', 'tw-rounded-full'];
  }
}
